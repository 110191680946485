import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ColemanSleepingBagforKidsPic from '../images/ColemanSleepingBagforKids.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import SleepingBagsPic from '../../../images/SleepingBags.jpg';


function ColemanSleepingBagforKids () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Coleman Sleeping Bag for Kids</h1>
          Average Rating (4.5) <Rating name="read-only" value={4.5} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.coleman.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ SleepingBagsPic} style={{height:'120px'}} alt="SleepingBags"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Coleman Sleeping Bag for Kids" height="130px" src={ColemanSleepingBagforKidsPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Parents love the Coleman Kids Sleeping Bag for its vibrant designs, which have made camping more enjoyable for their children. The lightweight and easy-to-roll-up feature is appreciated by many, making it convenient for family trips. A few reviews mention how well it retains warmth during cooler nights, ensuring that kids have a good night's sleep.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Kid-friendly designs</li>
<li className="ListItemStyle">Lightweight and easy to carry</li>
<li className="ListItemStyle">Good insulation for warmth</li>
<li className="ListItemStyle">Affordable price</li>
<li className="ListItemStyle">Machine washable</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited temperature range</li>
<li className="ListItemStyle">May be too small for older kids</li>
<li className="ListItemStyle">Doesn't compress much for packing</li>
<li className="ListItemStyle">Zipper may be stiff</li>
<li className="ListItemStyle">Less durable for rough use</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Kid-friendly designs</li>
<li className="ListItemStyle">Lightweight and easy to carry</li>
<li className="ListItemStyle">Good insulation for warmth</li>
<li className="ListItemStyle">Affordable price</li>
<li className="ListItemStyle">Machine washable</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited temperature range</li>
<li className="ListItemStyle">May be too small for older kids</li>
<li className="ListItemStyle">Doesn't compress much for packing</li>
<li className="ListItemStyle">Zipper may be stiff</li>
<li className="ListItemStyle">Less durable for rough use</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default ColemanSleepingBagforKids;

