import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import EOTechEXPS3HolographicSightPic from '../images/EOTechEXPS3HolographicSight.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import RedDotSightsPic from '../../../images/RedDotSights.jpg';


function EOTechEXPS3HolographicSight () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for EOTech EXPS3 Holographic Sight</h1>
          Average Rating (4.8) <Rating name="read-only" value={4.8} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.eotechinc.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ RedDotSightsPic} style={{height:'120px'}} alt="RedDotSights"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="EOTech EXPS3 Holographic Sight" height="130px" src={EOTechEXPS3HolographicSightPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Reviewers consistently mention the exceptional clarity and wide field of view the EXPS3 offers, stating it's perfect for dynamic shooting scenarios. The rugged build quality is frequently praised, especially for outdoor use. Many users also rave about the easy adjustments and the versatile brightness settings, catering well to different environments.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Outstanding optical clarity</li>
<li className="ListItemStyle">Durable and reliable</li>
<li className="ListItemStyle">Night vision compatible</li>
<li className="ListItemStyle">Quick target acquisition</li>
<li className="ListItemStyle">Multiple brightness settings</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price range</li>
<li className="ListItemStyle">Batteries can be expensive</li>
<li className="ListItemStyle">Weight may be an issue for some</li>
<li className="ListItemStyle">Adjustment controls can be stiff</li>
<li className="ListItemStyle">Not as compact as some competitors</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Outstanding optical clarity</li>
<li className="ListItemStyle">Durable and reliable</li>
<li className="ListItemStyle">Night vision compatible</li>
<li className="ListItemStyle">Quick target acquisition</li>
<li className="ListItemStyle">Multiple brightness settings</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price range</li>
<li className="ListItemStyle">Batteries can be expensive</li>
<li className="ListItemStyle">Weight may be an issue for some</li>
<li className="ListItemStyle">Adjustment controls can be stiff</li>
<li className="ListItemStyle">Not as compact as some competitors</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default EOTechEXPS3HolographicSight;

