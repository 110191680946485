import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import SatelliteCommunicatorsPic from '../images/SatelliteCommunicators.jpg';

//import GarmininReachMini2Pic from './images/GarmininReachMini2.png';
//import InReachSESatelliteCommunicatorPic from './images/InReachSESatelliteCommunicator.png';
//import IridiumExtreme9575Pic from './images/IridiumExtreme9575.png';
//import SpotGen4SatelliteMessengerPic from './images/SpotGen4SatelliteMessenger.png';
//import ZoleoSatelliteCommunicatorPic from './images/ZoleoSatelliteCommunicator.png';


function SatelliteCommunicators () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.garmin.com",
      //imageAlt:"Garmin inReach Mini 2",
      //imageRef:GarmininReachMini2Pic,
      id:"GarmininReachMini2",
      itemLink:"../Electronics/SatelliteCommunicators/Reviews/GarmininReachMini2",
      itemBoldText:"Garmin inReach Mini 2",
      itemText:"The Garmin inReach Mini 2 is a compact satellite communicator that allows users to send and receive texts, track their location, and even trigger an SOS in case of emergencies. It's designed for outdoor adventurers needing a lightweight and reliable communication tool with global coverage.",
      averageRating:4.8
    },
	    {
      manuLink:"https://www.garmin.com",
      //imageAlt:"InReach SE+ Satellite Communicator",
      //imageRef:InReachSESatelliteCommunicatorPic,
      id:"InReachSESatelliteCommunicator",
      itemLink:"../Electronics/SatelliteCommunicators/Reviews/InReachSESatelliteCommunicator",
      itemBoldText:"InReach SE+ Satellite Communicator",
      itemText:"The Garmin InReach SE+ offers two-way messaging and tracking, combined with a powerful SOS feature that connects users to a 24/7 emergency response team. This device is equipped with a robust interface, making it user-friendly for all outdoor pursuits.",
      averageRating:4.7
    },
	    {
      manuLink:"https://www.iridium.com/",
      //imageAlt:"Iridium Extreme 9575",
      //imageRef:IridiumExtreme9575Pic,
      id:"IridiumExtreme9575",
      itemLink:"../Electronics/SatelliteCommunicators/Reviews/IridiumExtreme9575",
      itemBoldText:"Iridium Extreme 9575",
      itemText:"The Iridium Extreme 9575 satellite communicator is designed for extreme conditions, providing a reliable communication link from virtually anywhere on the planet. It includes voice calling capabilities, SMS messaging, and has a SOS function for emergencies.",
      averageRating:4.5
    },
	    {
      manuLink:"https://www.findmespot.com",
      //imageAlt:"Spot Gen4 Satellite Messenger",
      //imageRef:SpotGen4SatelliteMessengerPic,
      id:"SpotGen4SatelliteMessenger",
      itemLink:"../Electronics/SatelliteCommunicators/Reviews/SpotGen4SatelliteMessenger",
      itemBoldText:"Spot Gen4 Satellite Messenger",
      itemText:"The Spot Gen4 Satellite Messenger allows outdoor enthusiasts to communicate their location and status to friends and family, even when outside of cell range. It features tracking capabilities and has an SOS button to alert emergency services.",
      averageRating:4.3
    },
	    {
      manuLink:"https://www.zoleo.com",
      //imageAlt:"Zoleo Satellite Communicator",
      //imageRef:ZoleoSatelliteCommunicatorPic,
      id:"ZoleoSatelliteCommunicator",
      itemLink:"../Electronics/SatelliteCommunicators/Reviews/ZoleoSatelliteCommunicator",
      itemBoldText:"Zoleo Satellite Communicator",
      itemText:"The Zoleo Satellite Communicator provides reliable two-way messaging through a global satellite network, even when you are off the grid. It features a user-friendly app that allows seamless messaging between the device and smartphone, making it ideal for outdoor enthusiasts.",
      averageRating:4.6
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top Satellite Communicators for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={SatelliteCommunicatorsPic} style={{height:'120px'}} alt="Satellite Communicators"></img>
        </td>
      </tr>
      </tbody>
    </table>


    If you get far off the beaten path, you likely won’t have cell service where you are. Having a satellite communicator with you can provide that link to friends and family, or emergency services, when needed. I have heard many stories of people being in the woods by themselves and getting hurt, without any way to communicate with the outside world. A satellite communicator can bring peace of mind to family members when you are deep in the outdoors. Below are some terrific options to have with you.

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default SatelliteCommunicators;

