import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ALPSOutdoorZPursuitPackPic from '../images/ALPSOutdoorZPursuitPack.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import HuntingPacksPic from '../../../images/HuntingPacks.jpg';


function ALPSOutdoorZPursuitPack () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for ALPS OutdoorZ Pursuit Pack</h1>
          Average Rating (4.3) <Rating name="read-only" value={4.3} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.alpsoutdoorz.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingPacksPic} style={{height:'120px'}} alt="HuntingPacks"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="ALPS OutdoorZ Pursuit Pack" height="130px" src={ALPSOutdoorZPursuitPackPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Reviewers often highlight the impressive value for the price, noting that it carries well and holds up to typical wear and tear. Many appreciate the number of pockets for organization, although some felt the zippers could be improved for smoother usage. Overall, it's seen as a solid choice for beginners.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Affordable price point</li>
<li className="ListItemStyle">Good organizational features</li>
<li className="ListItemStyle">Decent load capacity for day trips</li>
<li className="ListItemStyle">Comfortable for extended wear</li>
<li className="ListItemStyle">Durable enough for light use</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Zippers may not be the highest quality</li>
<li className="ListItemStyle">Limited capacity for extended trips</li>
<li className="ListItemStyle">Not as lightweight as higher-end options</li>
<li className="ListItemStyle">Material feels less durable in rough conditions</li>
<li className="ListItemStyle">Design may not appeal to all users</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Affordable price point</li>
<li className="ListItemStyle">Good organizational features</li>
<li className="ListItemStyle">Decent load capacity for day trips</li>
<li className="ListItemStyle">Comfortable for extended wear</li>
<li className="ListItemStyle">Durable enough for light use</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Zippers may not be the highest quality</li>
<li className="ListItemStyle">Limited capacity for extended trips</li>
<li className="ListItemStyle">Not as lightweight as higher-end options</li>
<li className="ListItemStyle">Material feels less durable in rough conditions</li>
<li className="ListItemStyle">Design may not appeal to all users</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default ALPSOutdoorZPursuitPack;

