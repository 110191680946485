import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import CampChef2BurnerCampStovePic from '../images/CampChef2BurnerCampStove.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import CampStovesPic from '../../../images/CampStoves.jpg';


function CampChef2BurnerCampStove () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Camp Chef 2 Burner Camp Stove</h1>
          Average Rating (4.7) <Rating name="read-only" value={4.7} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.campchef.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampStovesPic} style={{height:'120px'}} alt="CampStoves"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Camp Chef 2 Burner Camp Stove" height="130px" src={CampChef2BurnerCampStovePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users love the Camp Chef 2 Burner Camp Stove for its versatility and heat control, which allows for precise cooking. Many appreciate its durable construction, noting it stands up well against rugged camping conditions. Some users have commented on its compact size, making it easy to pack and transport for weekend trips.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Powerful burners for efficient cooking</li>
<li className="ListItemStyle">Adjustable heat controls for precision</li>
<li className="ListItemStyle">Durable and lightweight design</li>
<li className="ListItemStyle">Easy to clean cooking surface</li>
<li className="ListItemStyle">Compact and portable for easy transport</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Fuel can be expensive over time</li>
<li className="ListItemStyle">Takes a bit of time to ignite initially</li>
<li className="ListItemStyle">Requires additional accessories for full use</li>
<li className="ListItemStyle">Limited cooking space with two burners</li>
<li className="ListItemStyle">Can be noisy when in operation</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Powerful burners for efficient cooking</li>
<li className="ListItemStyle">Adjustable heat controls for precision</li>
<li className="ListItemStyle">Durable and lightweight design</li>
<li className="ListItemStyle">Easy to clean cooking surface</li>
<li className="ListItemStyle">Compact and portable for easy transport</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Fuel can be expensive over time</li>
<li className="ListItemStyle">Takes a bit of time to ignite initially</li>
<li className="ListItemStyle">Requires additional accessories for full use</li>
<li className="ListItemStyle">Limited cooking space with two burners</li>
<li className="ListItemStyle">Can be noisy when in operation</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default CampChef2BurnerCampStove;

