import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BenchmadeGrizzlyRidgeFoldingKnifePic from '../images/BenchmadeGrizzlyRidgeFoldingKnife.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import HuntingKnivesPic from '../../../images/HuntingKnives.jpg';


function BenchmadeGrizzlyRidgeFoldingKnife () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Benchmade Grizzly Ridge Folding Knife</h1>
          Average Rating (4.8) <Rating name="read-only" value={4.8} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.benchmade.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingKnivesPic} style={{height:'120px'}} alt="HuntingKnives"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Benchmade Grizzly Ridge Folding Knife" height="130px" src={BenchmadeGrizzlyRidgeFoldingKnifePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users rave about the knife's solid construction and the sharpness of the blade, which holds up well after extensive use. Many also appreciate the comfortable handle, which offers excellent control during detailed tasks. A few wished it came with a sheath for easier carrying.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Exceptional blade quality</li>
<li className="ListItemStyle">Comfortable grip for prolonged use</li>
<li className="ListItemStyle">Versatile for multiple tasks</li>
<li className="ListItemStyle">Safe and reliable locking mechanism</li>
<li className="ListItemStyle">Stylish design</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">On the pricier side</li>
<li className="ListItemStyle">Heavier than some competitors</li>
<li className="ListItemStyle">No sheath included</li>
<li className="ListItemStyle">D2 steel requires regular maintenance</li>
<li className="ListItemStyle">Limited color options</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Exceptional blade quality</li>
<li className="ListItemStyle">Comfortable grip for prolonged use</li>
<li className="ListItemStyle">Versatile for multiple tasks</li>
<li className="ListItemStyle">Safe and reliable locking mechanism</li>
<li className="ListItemStyle">Stylish design</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">On the pricier side</li>
<li className="ListItemStyle">Heavier than some competitors</li>
<li className="ListItemStyle">No sheath included</li>
<li className="ListItemStyle">D2 steel requires regular maintenance</li>
<li className="ListItemStyle">Limited color options</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default BenchmadeGrizzlyRidgeFoldingKnife;

