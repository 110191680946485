import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ColemanPortableCampingQuadChairPic from '../images/ColemanPortableCampingQuadChair.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import CampChairsPic from '../../../images/CampChairs.jpg';


function ColemanPortableCampingQuadChair () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Coleman Portable Camping Quad Chair</h1>
          Average Rating (4.5) <Rating name="read-only" value={4.5} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.coleman.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampChairsPic} style={{height:'120px'}} alt="CampChairs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Coleman Portable Camping Quad Chair" height="130px" src={ColemanPortableCampingQuadChairPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Many users appreciate the comfort of the Coleman Quad Chair, stating it's great for long hours of sitting around the campfire. Reviewers often mention its easy setup and lightweight design, making it ideal for transport. A few users appreciate the cup holder feature, noting it adds convenience during outdoor gatherings.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Comfortable seating with armrests</li>
<li className="ListItemStyle">Lightweight and portable</li>
<li className="ListItemStyle">Durable construction</li>
<li className="ListItemStyle">Easy to set up and take down</li>
<li className="ListItemStyle">Includes cup holder</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Not very compact when folded</li>
<li className="ListItemStyle">Limited color options</li>
<li className="ListItemStyle">Seat height may be low for taller individuals</li>
<li className="ListItemStyle">May not support heavier weights comfortably</li>
<li className="ListItemStyle">Fabric can wear out over time</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Comfortable seating with armrests</li>
<li className="ListItemStyle">Lightweight and portable</li>
<li className="ListItemStyle">Durable construction</li>
<li className="ListItemStyle">Easy to set up and take down</li>
<li className="ListItemStyle">Includes cup holder</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Not very compact when folded</li>
<li className="ListItemStyle">Limited color options</li>
<li className="ListItemStyle">Seat height may be low for taller individuals</li>
<li className="ListItemStyle">May not support heavier weights comfortably</li>
<li className="ListItemStyle">Fabric can wear out over time</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default ColemanPortableCampingQuadChair;

