import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import FireStarterSticksbyCoghlansPic from '../images/FireStarterSticksbyCoghlans.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import FireStartersPic from '../../../images/FireStarters.jpg';


function FireStarterSticksbyCoghlans () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Fire Starter Sticks (by Coghlan's)</h1>
          Average Rating (4.5) <Rating name="read-only" value={4.5} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.coghlans.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ FireStartersPic} style={{height:'120px'}} alt="FireStarters"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Fire Starter Sticks (by Coghlan's)" height="130px" src={FireStarterSticksbyCoghlansPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users appreciate how quickly these sticks ignite, with one reviewer noting they lit a campfire in under five minutes. The sticks are also praised for their ability to get a fire going even when the wood is damp. However, some users mention a strong odor when burning, which can be a drawback during indoor use.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Ignites quickly</li>
<li className="ListItemStyle">Burns for several minutes</li>
<li className="ListItemStyle">Effective in wet conditions</li>
<li className="ListItemStyle">Lightweight and easy to pack</li>
<li className="ListItemStyle">Versatile for different fire types</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Strong chemical smell</li>
<li className="ListItemStyle">Can leave residue</li>
<li className="ListItemStyle">Not as effective over long periods</li>
<li className="ListItemStyle">Requires multiple sticks for larger fires</li>
<li className="ListItemStyle">Potentially flammable packaging</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Ignites quickly</li>
<li className="ListItemStyle">Burns for several minutes</li>
<li className="ListItemStyle">Effective in wet conditions</li>
<li className="ListItemStyle">Lightweight and easy to pack</li>
<li className="ListItemStyle">Versatile for different fire types</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Strong chemical smell</li>
<li className="ListItemStyle">Can leave residue</li>
<li className="ListItemStyle">Not as effective over long periods</li>
<li className="ListItemStyle">Requires multiple sticks for larger fires</li>
<li className="ListItemStyle">Potentially flammable packaging</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default FireStarterSticksbyCoghlans;

