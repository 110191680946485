import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import UnidenGMR50992CKHSPic from '../images/UnidenGMR50992CKHS.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import TwoWayRadiosPic from '../../../images/TwoWayRadios.jpg';


function UnidenGMR50992CKHS () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Uniden GMR5099-2CKHS</h1>
          Average Rating (4.3) <Rating name="read-only" value={4.3} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.uniden.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ TwoWayRadiosPic} style={{height:'120px'}} alt="2WayRadios"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Uniden GMR5099-2CKHS" height="130px" src={UnidenGMR50992CKHSPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users appreciate the strong range of the Uniden GMR5099, particularly in large open areas, allowing for effective communication across distances. The waterproof feature was also praised, especially during unexpected rain. However, a few users noted that the charger could be a bit finicky, occasionally requiring multiple attempts to connect properly.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Range of up to 50 miles</li>
<li className="ListItemStyle">Waterproof design</li>
<li className="ListItemStyle">Clear sound quality</li>
<li className="ListItemStyle">Long battery life</li>
<li className="ListItemStyle">Multiple channels available</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Charger can be unreliable</li>
<li className="ListItemStyle">Bulkier than some alternatives</li>
<li className="ListItemStyle">A bit pricey</li>
<li className="ListItemStyle">Screen could be larger</li>
<li className="ListItemStyle">Limited accessories included</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Range of up to 50 miles</li>
<li className="ListItemStyle">Waterproof design</li>
<li className="ListItemStyle">Clear sound quality</li>
<li className="ListItemStyle">Long battery life</li>
<li className="ListItemStyle">Multiple channels available</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Charger can be unreliable</li>
<li className="ListItemStyle">Bulkier than some alternatives</li>
<li className="ListItemStyle">A bit pricey</li>
<li className="ListItemStyle">Screen could be larger</li>
<li className="ListItemStyle">Limited accessories included</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default UnidenGMR50992CKHS;

