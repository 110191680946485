import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BuckKnives119SpecialPic from '../images/BuckKnives119Special.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import HuntingKnivesPic from '../../../images/HuntingKnives.jpg';


function BuckKnives119Special () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Buck Knives 119 Special</h1>
          Average Rating (4.6) <Rating name="read-only" value={4.6} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.buckknives.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingKnivesPic} style={{height:'120px'}} alt="HuntingKnives"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Buck Knives 119 Special" height="130px" src={BuckKnives119SpecialPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users admire the blade's robustness and its ability to retain sharpness even after heavy use. The nostalgic design received many compliments, making it a favorite for collectors. Some mentioned that its weight can be cumbersome for extended carry.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Strong and durable blade</li>
<li className="ListItemStyle">Maintains sharpness for long periods</li>
<li className="ListItemStyle">Classic and timeless design</li>
<li className="ListItemStyle">Perfect for big game processing</li>
<li className="ListItemStyle">Easy to sharpen</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier than folding knives</li>
<li className="ListItemStyle">Requires a sheath for carrying</li>
<li className="ListItemStyle">No assisted opening feature</li>
<li className="ListItemStyle">Limited to fixed-blade design</li>
<li className="ListItemStyle">Can be pricey for some users</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Strong and durable blade</li>
<li className="ListItemStyle">Maintains sharpness for long periods</li>
<li className="ListItemStyle">Classic and timeless design</li>
<li className="ListItemStyle">Perfect for big game processing</li>
<li className="ListItemStyle">Easy to sharpen</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier than folding knives</li>
<li className="ListItemStyle">Requires a sheath for carrying</li>
<li className="ListItemStyle">No assisted opening feature</li>
<li className="ListItemStyle">Limited to fixed-blade design</li>
<li className="ListItemStyle">Can be pricey for some users</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default BuckKnives119Special;

