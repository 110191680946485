import * as React from 'react';
import { useState, useEffect} from "react";
import Typography from '@mui/material/Typography';
import { Paper, useMediaQuery } from '@mui/material';


function PrivacyPolicy() {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return ( 
    <div>
            <div style={{marginLeft: isSmallScreen ? '20px' : '220px', marginTop: '150px', marginRight:'20px'}}>
            <div className="headingone" style={{textAlign:'center'}}>JoinMeOutdoors.com Privacy Policy</div>
            <div style={{marginLeft: isSmallScreen ? '20px' : '60px', marginTop: '10px', marginRight:'20px'}}>
            <Typography component={'span'} variant={'body1'}>

<Paper  elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}>                  
<b>Effective Date:</b> 11/01/2024
<br/><br/>

JoinMeOutdoors.com ("we," "our," or "us") is committed to safeguarding your privacy. This Privacy Policy outlines how we handle information collected from visitors to our website, JoinMeOutdoors.com (the "Site"). Importantly, we do not collect, store, or process any personal information.  
<br/><br/>
<h4>1. Information We Do Not Collect</h4>

We do not collect any personally identifiable information (PII) from users. This includes, but is not limited to:  
<br/><br/>
- Names  
<br/>
- Email addresses  
<br/>
- Phone numbers 
<br/>
- Payment details  
<br/>
- IP addresses or other device-specific identifiers  

<br/>
<h4>2. Non-Personal Information</h4>

We may collect anonymized, aggregated data about website traffic for analytical purposes (e.g., total number of visitors). This data does not identify individuals and is used solely to improve the functionality of the Site.  
<br/>
<h4>3. Cookies and Tracking Technologies</h4>

We do not use cookies, web beacons, or similar tracking technologies to collect or store user information.  
<br/><br/>
<h4>4. Compliance with Privacy Laws</h4>


a. California Consumer Privacy Act (CCPA)
<br/><br/>
Under the CCPA, California residents have specific rights regarding their personal data. Since we do not collect personal information, the rights provided under the CCPA—such as access, deletion, or opt-out—are not applicable.  

If you have any questions about our compliance with CCPA, feel free to contact us at [Insert Contact Email].  
<br/><br/>
b. Children’s Online Privacy Protection Act (COPPA)
<br/><br/>
We comply with COPPA by not collecting personal information from users, including those under the age of 13. Our Site is safe for all users and does not require personal information for any features.  
<br/><br/>
c. General Data Protection Regulation (GDPR)
<br/><br/>
As per the GDPR, users in the European Economic Area (EEA) have rights related to their personal data. However, since we do not collect or process personal data, these rights are not applicable.  
<br/><br/>
If you have concerns about our GDPR compliance, please contact us at [Insert Contact Email].  
<br/><br/>
d. Personal Information Protection and Electronic Documents Act (PIPEDA)
<br/><br/>
PIPEDA governs the collection and use of personal information in Canada. Since we do not collect personal information, PIPEDA requirements do not apply.  
<br/><br/>
<h4>5. Third-Party Links</h4>

Our Site may include links to external websites. Please note that we are not responsible for the privacy practices or content of these third-party sites. We encourage you to review their privacy policies before interacting with them.  
<br/><br/>
<h4>6. Data Security</h4>

While we do not collect personal data, we are committed to ensuring the security and integrity of our website. We implement best practices to protect against unauthorized access and maintain a safe browsing experience.  
<br/><br/>
<h4>7. Changes to This Privacy Policy</h4>

We may update this Privacy Policy from time to time to reflect changes in our practices or for operational, legal, or regulatory reasons. The updated version will always be available on this page with the "Effective Date" listed.  
<br/><br/>
<h4>8. Contact Us</h4>

If you have questions, concerns, or requests regarding this Privacy Policy or how we operate our website, you may contact us at:  
<br/><br/>
<b>Email:</b> george@joinmeoutdoors.com
<br/><br/>
By using JoinMeOutdoors.com, you acknowledge and agree to this Privacy Policy.

</Paper>

            </Typography>
            </div>
              </div>
              <br/>
    </div> 
  );
}

export default PrivacyPolicy;