import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GarmininReachMini2Pic from '../images/GarmininReachMini2.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import SatelliteCommunicatorsPic from '../../../images/SatelliteCommunicators.jpg';


function GarmininReachMini2 () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Garmin inReach Mini 2</h1>
          Average Rating (4.8) <Rating name="read-only" value={4.8} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.garmin.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ SatelliteCommunicatorsPic} style={{height:'120px'}} alt="SatelliteCommunicators"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Garmin inReach Mini 2" height="130px" src={GarmininReachMini2Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users rave about the inReach Mini 2's reliability, especially during challenging hikes where cell service is spotty. Many appreciate its compact design, making it easy to carry on any adventure, and the seamless connectivity to Garmin's ecosystem is a bonus. However, some noted the learning curve associated with using the device for the first time, requiring a bit of patience to navigate all its features.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Global satellite coverage</li>
<li className="ListItemStyle">Compact and lightweight design</li>
<li className="ListItemStyle">SOS feature for emergencies</li>
<li className="ListItemStyle">Easy integration with Garmin devices</li>
<li className="ListItemStyle">Long battery life</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Requires a subscription plan</li>
<li className="ListItemStyle">Learning curve for new users</li>
<li className="ListItemStyle">Limited screen size</li>
<li className="ListItemStyle">Occasional connectivity issues in remote areas</li>
<li className="ListItemStyle">Not waterproof (requires a case)</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Global satellite coverage</li>
<li className="ListItemStyle">Compact and lightweight design</li>
<li className="ListItemStyle">SOS feature for emergencies</li>
<li className="ListItemStyle">Easy integration with Garmin devices</li>
<li className="ListItemStyle">Long battery life</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Requires a subscription plan</li>
<li className="ListItemStyle">Learning curve for new users</li>
<li className="ListItemStyle">Limited screen size</li>
<li className="ListItemStyle">Occasional connectivity issues in remote areas</li>
<li className="ListItemStyle">Not waterproof (requires a case)</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default GarmininReachMini2;

