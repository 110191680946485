import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GarminGPSMAP66iPic from '../images/GarminGPSMAP66i.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import HandheldGPSPic from '../../../images/HandheldGPS.jpg';


function GarminGPSMAP66i () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Garmin GPSMAP 66i</h1>
          Average Rating (4.7) <Rating name="read-only" value={4.7} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.garmin.com/en-US/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ HandheldGPSPic} style={{height:'120px'}} alt="HandheldGPS"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Garmin GPSMAP 66i" height="130px" src={GarminGPSMAP66iPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users appreciate the GPSMAP 66i for its exceptional accuracy and ease of use, particularly highlighting its detailed mapping capabilities that are invaluable in the wilderness. Many users also laud its durable build and long battery life, making it a reliable companion for extended trips. However, some have mentioned that the learning curve for the satellite communication features can be somewhat steep, especially for first-time users.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Exceptional mapping accuracy</li>
<li className="ListItemStyle">Durable and weather-resistant design</li>
<li className="ListItemStyle">Long battery life</li>
<li className="ListItemStyle">Integrated satellite communication</li>
<li className="ListItemStyle">User-friendly interface</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Steep learning curve for satellite features</li>
<li className="ListItemStyle">Higher price point</li>
<li className="ListItemStyle">Occasional software updates required</li>
<li className="ListItemStyle">Screen visibility issues in direct sunlight</li>
<li className="ListItemStyle">Limited built-in storage for maps</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Exceptional mapping accuracy</li>
<li className="ListItemStyle">Durable and weather-resistant design</li>
<li className="ListItemStyle">Long battery life</li>
<li className="ListItemStyle">Integrated satellite communication</li>
<li className="ListItemStyle">User-friendly interface</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Steep learning curve for satellite features</li>
<li className="ListItemStyle">Higher price point</li>
<li className="ListItemStyle">Occasional software updates required</li>
<li className="ListItemStyle">Screen visibility issues in direct sunlight</li>
<li className="ListItemStyle">Limited built-in storage for maps</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default GarminGPSMAP66i;

