import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import HuntingKnivesPic from '../images/HuntingKnives.jpg';

//import BenchmadeGrizzlyRidgeFoldingKnifePic from './images/BenchmadeGrizzlyRidgeFoldingKnife.png';
//import BuckKnives119SpecialPic from './images/BuckKnives119Special.png';
//import GerberStrongArmFixedBladeKnifePic from './images/GerberStrongArmFixedBladeKnife.png';
//import HavalonPirantaZFoldingKnifePic from './images/HavalonPirantaZFoldingKnife.png';
//import KershawKenOnionBlurFoldingKnifePic from './images/KershawKenOnionBlurFoldingKnife.png';


function HuntingKnives () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.benchmade.com/",
      //imageAlt:"Benchmade Grizzly Ridge Folding Knife",
      //imageRef:BenchmadeGrizzlyRidgeFoldingKnifePic,
      id:"BenchmadeGrizzlyRidgeFoldingKnife",
      itemLink:"../HuntingGear/HuntingKnives/Reviews/BenchmadeGrizzlyRidgeFoldingKnife",
      itemBoldText:"Benchmade Grizzly Ridge Folding Knife",
      itemText:"The Benchmade Grizzly Ridge is an all-purpose folding knife with a strong D2 steel blade that excels in outdoor tasks. Its Axis lock mechanism ensures safety, and the textured handle provides a secure grip for any conditions.",
      averageRating:4.8
    },
	    {
      manuLink:"https://www.buckknives.com",
      //imageAlt:"Buck Knives 119 Special",
      //imageRef:BuckKnives119SpecialPic,
      id:"BuckKnives119Special",
      itemLink:"../HuntingGear/HuntingKnives/Reviews/BuckKnives119Special",
      itemBoldText:"Buck Knives 119 Special",
      itemText:"A classic fixed-blade knife, the Buck Knives 119 Special is renowned for its strength and reliability. With a stainless steel blade and a comfortable phenolic handle, this knife is perfect for big game hunting and outdoor tasks.",
      averageRating:4.6
    },
	    {
      manuLink:"https://www.gerbergear.com",
      //imageAlt:"Gerber StrongArm Fixed Blade Knife",
      //imageRef:GerberStrongArmFixedBladeKnifePic,
      id:"GerberStrongArmFixedBladeKnife",
      itemLink:"../HuntingGear/HuntingKnives/Reviews/GerberStrongArmFixedBladeKnife",
      itemBoldText:"Gerber StrongArm Fixed Blade Knife",
      itemText:"The Gerber StrongArm is a reliable fixed-blade knife designed for heavy-duty use in the field. With a full-tang construction and a ceramic coating, it offers both durability and precision for hunting and camping activities.",
      averageRating:4.4
    },
	    {
      manuLink:"https://www.havalon.com/",
      //imageAlt:"Havalon Piranta Z Folding Knife",
      //imageRef:HavalonPirantaZFoldingKnifePic,
      id:"HavalonPirantaZFoldingKnife",
      itemLink:"../HuntingGear/HuntingKnives/Reviews/HavalonPirantaZFoldingKnife",
      itemBoldText:"Havalon Piranta Z Folding Knife",
      itemText:"The Havalon Piranta Z features a lightweight design with replaceable blades, making it ideal for field dressing and skinning. Its ergonomic handle ensures a comfortable grip, while the blade change system offers quick and easy replacements.",
      averageRating:4.7
    },
	    {
      manuLink:"https://kershaw.kaiusa.com/",
      //imageAlt:"Kershaw Ken Onion Blur Folding Knife",
      //imageRef:KershawKenOnionBlurFoldingKnifePic,
      id:"KershawKenOnionBlurFoldingKnife",
      itemLink:"../HuntingGear/HuntingKnives/Reviews/KershawKenOnionBlurFoldingKnife",
      itemBoldText:"Kershaw Ken Onion Blur Folding Knife",
      itemText:"The Kershaw Ken Onion Blur is a versatile folding knife known for its assisted opening mechanism and durable stainless steel blade. Its textured handle ensures a secure grip, and it comes with a reversible pocket clip for convenience.",
      averageRating:4.5
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top Hunting Knives for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={HuntingKnivesPic} style={{height:'120px'}} alt="Hunting Knives"></img>
        </td>
      </tr>
      </tbody>
    </table>


    The right hunting knife is an incredibly valuable tool used to finish out the hunt. Processing the game you’ve worked so hard to harvest is where the work begins. That work can be made much easier when you have a great knife for the job. Below are some great options to finish out your successful hunt.

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default HuntingKnives;

