import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BushnellTRS25RedDotSightPic from '../images/BushnellTRS25RedDotSight.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import RedDotSightsPic from '../../../images/RedDotSights.jpg';


function BushnellTRS25RedDotSight () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Bushnell TRS-25 Red Dot Sight</h1>
          Average Rating (4.4) <Rating name="read-only" value={4.4} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.bushnell.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ RedDotSightsPic} style={{height:'120px'}} alt="RedDotSights"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Bushnell TRS-25 Red Dot Sight" height="130px" src={BushnellTRS25RedDotSightPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Many users appreciate the affordability of the TRS-25 while still enjoying reliable performance on the range. The robust build is often mentioned, with several reviews noting it has survived rough treatment without losing zero. Additionally, users enjoy the simplicity of the controls and the effectiveness of the sight at close ranges.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Excellent value for the price</li>
<li className="ListItemStyle">Compact and lightweight design</li>
<li className="ListItemStyle">Durable and waterproof</li>
<li className="ListItemStyle">User-friendly controls</li>
<li className="ListItemStyle">Acceptable battery life</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited brightness settings</li>
<li className="ListItemStyle">Dot may be too big for some users</li>
<li className="ListItemStyle">Eye relief can be challenging</li>
<li className="ListItemStyle">Requires careful alignment</li>
<li className="ListItemStyle">Occasional quality control issues</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Excellent value for the price</li>
<li className="ListItemStyle">Compact and lightweight design</li>
<li className="ListItemStyle">Durable and waterproof</li>
<li className="ListItemStyle">User-friendly controls</li>
<li className="ListItemStyle">Acceptable battery life</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited brightness settings</li>
<li className="ListItemStyle">Dot may be too big for some users</li>
<li className="ListItemStyle">Eye relief can be challenging</li>
<li className="ListItemStyle">Requires careful alignment</li>
<li className="ListItemStyle">Occasional quality control issues</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default BushnellTRS25RedDotSight;

