import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import REICoopMagma15SleepingBagPic from '../images/REICoopMagma15SleepingBag.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import SleepingBagsPic from '../../../images/SleepingBags.jpg';


function REICoopMagma15SleepingBag () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for REI Co-op Magma 15 Sleeping Bag</h1>
          Average Rating (4.8) <Rating name="read-only" value={4.8} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.rei.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ SleepingBagsPic} style={{height:'120px'}} alt="SleepingBags"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="REI Co-op Magma 15 Sleeping Bag" height="130px" src={REICoopMagma15SleepingBagPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users rave about the REI Magma's excellent insulation, highlighting how it keeps them warm throughout chilly nights. The compressibility is another standout feature, allowing for easy packing into small spaces. Additionally, many appreciate the overall comfort level, making it a favorite for multi-day backcountry trips.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-quality down insulation</li>
<li className="ListItemStyle">Very compact for backpacking</li>
<li className="ListItemStyle">Warm and comfortable</li>
<li className="ListItemStyle">Versatile temperature range</li>
<li className="ListItemStyle">Durable materials</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">High price point</li>
<li className="ListItemStyle">Requires extra care during cleaning</li>
<li className="ListItemStyle">Not fully waterproof</li>
<li className="ListItemStyle">Zipper can be tricky to use</li>
<li className="ListItemStyle">May feel snug for larger users</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-quality down insulation</li>
<li className="ListItemStyle">Very compact for backpacking</li>
<li className="ListItemStyle">Warm and comfortable</li>
<li className="ListItemStyle">Versatile temperature range</li>
<li className="ListItemStyle">Durable materials</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">High price point</li>
<li className="ListItemStyle">Requires extra care during cleaning</li>
<li className="ListItemStyle">Not fully waterproof</li>
<li className="ListItemStyle">Zipper can be tricky to use</li>
<li className="ListItemStyle">May feel snug for larger users</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default REICoopMagma15SleepingBag;

