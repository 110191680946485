import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import SpotGen4SatelliteMessengerPic from '../images/SpotGen4SatelliteMessenger.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import SatelliteCommunicatorsPic from '../../../images/SatelliteCommunicators.jpg';


function SpotGen4SatelliteMessenger () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Spot Gen4 Satellite Messenger</h1>
          Average Rating (4.3) <Rating name="read-only" value={4.3} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.findmespot.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ SatelliteCommunicatorsPic} style={{height:'120px'}} alt="SatelliteCommunicators"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Spot Gen4 Satellite Messenger" height="130px" src={SpotGen4SatelliteMessengerPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users appreciate the Spot Gen4 for its straightforward functionality and solid reliability in providing location updates. Many outdoor adventurers find peace of mind knowing they can trigger an SOS in an emergency. However, there are a few comments about the device being slightly bulkier than expected which can be a nuance for ultralight backpackers.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">SOS emergency feature</li>
<li className="ListItemStyle">Location tracking services</li>
<li className="ListItemStyle">Easy to use</li>
<li className="ListItemStyle">Durable build</li>
<li className="ListItemStyle">Affordable option for basic needs</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Less intuitive interface</li>
<li className="ListItemStyle">Bulky compared to some alternatives</li>
<li className="ListItemStyle">Limited messaging capabilities</li>
<li className="ListItemStyle">Subscription fees required</li>
<li className="ListItemStyle">Signal may be unreliable in dense forests</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">SOS emergency feature</li>
<li className="ListItemStyle">Location tracking services</li>
<li className="ListItemStyle">Easy to use</li>
<li className="ListItemStyle">Durable build</li>
<li className="ListItemStyle">Affordable option for basic needs</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Less intuitive interface</li>
<li className="ListItemStyle">Bulky compared to some alternatives</li>
<li className="ListItemStyle">Limited messaging capabilities</li>
<li className="ListItemStyle">Subscription fees required</li>
<li className="ListItemStyle">Signal may be unreliable in dense forests</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default SpotGen4SatelliteMessenger;

