import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BICLighterPic from '../images/BICLighter.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import FireStartersPic from '../../../images/FireStarters.jpg';


function BICLighter () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for BIC Lighter</h1>
          Average Rating (4.7) <Rating name="read-only" value={4.7} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.bic.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ FireStartersPic} style={{height:'120px'}} alt="FireStarters"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="BIC Lighter" height="130px" src={BICLighterPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Many users love the dependability of the BIC Lighter, stating that it always lights when needed. It's also appreciated for its longevity, with several users mentioning how long they last without running out of fuel. Additionally, the affordability of these lighters makes them a staple in many camping kits.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Very reliable ignition</li>
<li className="ListItemStyle">Affordable price</li>
<li className="ListItemStyle">Compact and lightweight</li>
<li className="ListItemStyle">Long-lasting fuel</li>
<li className="ListItemStyle">Available in multiple colors and designs</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Can run out of fuel surprisingly fast</li>
<li className="ListItemStyle">Not waterproof</li>
<li className="ListItemStyle">Can be lost easily in the wilderness</li>
<li className="ListItemStyle">Flame height is non-adjustable</li>
<li className="ListItemStyle">Requires manual dexterity to use</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Very reliable ignition</li>
<li className="ListItemStyle">Affordable price</li>
<li className="ListItemStyle">Compact and lightweight</li>
<li className="ListItemStyle">Long-lasting fuel</li>
<li className="ListItemStyle">Available in multiple colors and designs</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Can run out of fuel surprisingly fast</li>
<li className="ListItemStyle">Not waterproof</li>
<li className="ListItemStyle">Can be lost easily in the wilderness</li>
<li className="ListItemStyle">Flame height is non-adjustable</li>
<li className="ListItemStyle">Requires manual dexterity to use</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default BICLighter;

