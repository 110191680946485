import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GarmineTrex32xPic from '../images/GarmineTrex32x.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import HandheldGPSPic from '../../../images/HandheldGPS.jpg';


function GarmineTrex32x () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Garmin eTrex 32x</h1>
          Average Rating (4.6) <Rating name="read-only" value={4.6} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.garmin.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ HandheldGPSPic} style={{height:'120px'}} alt="HandheldGPS"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Garmin eTrex 32x" height="130px" src={GarmineTrex32xPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users have praised the eTrex 32x for its portability and effectiveness in tracking and navigating various trails. Many find the preloaded topo maps very helpful, allowing for effective route planning. However, some users have reported issues with the menu navigation being somewhat cumbersome at times.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Compact and lightweight</li>
<li className="ListItemStyle">High-sensitivity GPS receiver</li>
<li className="ListItemStyle">Preloaded topo maps</li>
<li className="ListItemStyle">User-friendly interface</li>
<li className="ListItemStyle">Affordable price</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Menu navigation can be cumbersome</li>
<li className="ListItemStyle">Small screen size</li>
<li className="ListItemStyle">Limited internal memory for additional maps</li>
<li className="ListItemStyle">Connectivity options are basic</li>
<li className="ListItemStyle">Battery life can vary with use</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Compact and lightweight</li>
<li className="ListItemStyle">High-sensitivity GPS receiver</li>
<li className="ListItemStyle">Preloaded topo maps</li>
<li className="ListItemStyle">User-friendly interface</li>
<li className="ListItemStyle">Affordable price</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Menu navigation can be cumbersome</li>
<li className="ListItemStyle">Small screen size</li>
<li className="ListItemStyle">Limited internal memory for additional maps</li>
<li className="ListItemStyle">Connectivity options are basic</li>
<li className="ListItemStyle">Battery life can vary with use</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default GarmineTrex32x;

