import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import NikonMonarch5Pic from '../images/NikonMonarch5.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import BinocularsPic from '../../../images/Binoculars.jpg';


function NikonMonarch5 () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Nikon Monarch 5</h1>
          Average Rating (4.6) <Rating name="read-only" value={4.6} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.nikonusa.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ BinocularsPic} style={{height:'120px'}} alt="Binoculars"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Nikon Monarch 5" height="130px" src={NikonMonarch5Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Nikon Monarch 5 has received accolades for its lightweight build and excellent optics, with users noting the sharpness of the images even at a distance. One reviewer mentioned their effectiveness for hunting in challenging lighting, while others appreciate the comfortable grip that allows for easy and prolonged use. Many users also highlighted the value for money, often comparing them favorably against more expensive models.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Wide field of view</li>
<li className="ListItemStyle">High-resolution optics</li>
<li className="ListItemStyle">Lightweight and compact</li>
<li className="ListItemStyle">Effective in low-light conditions</li>
<li className="ListItemStyle">Great value for money</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Some users find the focus wheel too sensitive</li>
<li className="ListItemStyle">Rubber armor may wear over time</li>
<li className="ListItemStyle">Eye cups may feel flimsy</li>
<li className="ListItemStyle">Limited color options</li>
<li className="ListItemStyle">No tripod adapter included</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Wide field of view</li>
<li className="ListItemStyle">High-resolution optics</li>
<li className="ListItemStyle">Lightweight and compact</li>
<li className="ListItemStyle">Effective in low-light conditions</li>
<li className="ListItemStyle">Great value for money</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Some users find the focus wheel too sensitive</li>
<li className="ListItemStyle">Rubber armor may wear over time</li>
<li className="ListItemStyle">Eye cups may feel flimsy</li>
<li className="ListItemStyle">Limited color options</li>
<li className="ListItemStyle">No tripod adapter included</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default NikonMonarch5;

