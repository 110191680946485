import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import StealthCamG36NGPic from '../images/StealthCamG36NG.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import TrailCamsPic from '../../../images/TrailCams.jpg';


function StealthCamG36NG () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Stealth Cam G36NG</h1>
          Average Rating (4.2) <Rating name="read-only" value={4.2} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.stealthcam.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ TrailCamsPic} style={{height:'120px'}} alt="TrailCams"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Stealth Cam G36NG" height="130px" src={StealthCamG36NGPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Reviewers love the No Glow feature, which allows them to capture images without spooking wildlife, making it perfect for hunters. Many also appreciate the camera's video capabilities, which provide clear footage. Some users mentioned the setup was initially confusing but became easier with practice, demonstrating that patience pays off with this reliable cam.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">No Glow technology</li>
<li className="ListItemStyle">Excellent image and video quality</li>
<li className="ListItemStyle">Customizable settings</li>
<li className="ListItemStyle">Reliable performance</li>
<li className="ListItemStyle">Affordable price</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Setup can be confusing</li>
<li className="ListItemStyle">Software may need updates</li>
<li className="ListItemStyle">Trigger speed could be faster</li>
<li className="ListItemStyle">Occasional battery issues</li>
<li className="ListItemStyle">Limited field of view</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">No Glow technology</li>
<li className="ListItemStyle">Excellent image and video quality</li>
<li className="ListItemStyle">Customizable settings</li>
<li className="ListItemStyle">Reliable performance</li>
<li className="ListItemStyle">Affordable price</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Setup can be confusing</li>
<li className="ListItemStyle">Software may need updates</li>
<li className="ListItemStyle">Trigger speed could be faster</li>
<li className="ListItemStyle">Occasional battery issues</li>
<li className="ListItemStyle">Limited field of view</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default StealthCamG36NG;

