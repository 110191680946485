import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import Badlands2200PackPic from '../images/Badlands2200Pack.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import HuntingPacksPic from '../../../images/HuntingPacks.jpg';


function Badlands2200Pack () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Badlands 2200 Pack</h1>
          Average Rating (4.5) <Rating name="read-only" value={4.5} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://badlandspacks.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingPacksPic} style={{height:'120px'}} alt="HuntingPacks"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Badlands 2200 Pack" height="130px" src={Badlands2200PackPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Customers appreciate the pack's lightweight frame and the ease of access it provides to all compartments. The hydration system works well and is a major plus for those going on long hunts. A few users, however, noted that it could benefit from additional padding for comfort during extended periods of wear.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Lightweight and comfortable design</li>
<li className="ListItemStyle">Quick-access pockets for convenience</li>
<li className="ListItemStyle">Effective hydration system</li>
<li className="ListItemStyle">Durable and weatherproof construction</li>
<li className="ListItemStyle">Great for day trips and short hunts</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Needs more padding for prolonged wear</li>
<li className="ListItemStyle">Smaller capacity than some competitors</li>
<li className="ListItemStyle">Limited load-bearing capability for heavy gear</li>
<li className="ListItemStyle">May not fit larger backpacks well</li>
<li className="ListItemStyle">Some seams may fray after extensive use</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Lightweight and comfortable design</li>
<li className="ListItemStyle">Quick-access pockets for convenience</li>
<li className="ListItemStyle">Effective hydration system</li>
<li className="ListItemStyle">Durable and weatherproof construction</li>
<li className="ListItemStyle">Great for day trips and short hunts</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Needs more padding for prolonged wear</li>
<li className="ListItemStyle">Smaller capacity than some competitors</li>
<li className="ListItemStyle">Limited load-bearing capability for heavy gear</li>
<li className="ListItemStyle">May not fit larger backpacks well</li>
<li className="ListItemStyle">Some seams may fray after extensive use</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default Badlands2200Pack;

