import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MidlandGXT1000VP4Pic from '../images/MidlandGXT1000VP4.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import TwoWayRadiosPic from '../../../images/TwoWayRadios.jpg';


function MidlandGXT1000VP4 () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Midland GXT1000VP4</h1>
          Average Rating (4.7) <Rating name="read-only" value={4.7} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://midlandradio.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ TwoWayRadiosPic} style={{height:'120px'}} alt="2WayRadios"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Midland GXT1000VP4" height="130px" src={MidlandGXT1000VP4Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Reviewers rave about the Midland GXT1000VP4's impressive range and clarity, making it a favorite for family camping trips. The additional weather alert function has provided peace of mind during outdoor excursions. However, some users wished for better battery life during extended uses, especially for long hikes.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">36-mile range</li>
<li className="ListItemStyle">50 channels with privacy codes</li>
<li className="ListItemStyle">NOAA weather alerts</li>
<li className="ListItemStyle">Compact and durable</li>
<li className="ListItemStyle">Clear audio quality</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Battery life could be improved</li>
<li className="ListItemStyle">Limited belt clip durability</li>
<li className="ListItemStyle">May require adjustments for optimal clarity</li>
<li className="ListItemStyle">A bit heavy for prolonged carrying</li>
<li className="ListItemStyle">Pricey for casual users</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">36-mile range</li>
<li className="ListItemStyle">50 channels with privacy codes</li>
<li className="ListItemStyle">NOAA weather alerts</li>
<li className="ListItemStyle">Compact and durable</li>
<li className="ListItemStyle">Clear audio quality</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Battery life could be improved</li>
<li className="ListItemStyle">Limited belt clip durability</li>
<li className="ListItemStyle">May require adjustments for optimal clarity</li>
<li className="ListItemStyle">A bit heavy for prolonged carrying</li>
<li className="ListItemStyle">Pricey for casual users</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default MidlandGXT1000VP4;

