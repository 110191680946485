import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import REICoopFlexliteChairPic from '../images/REICoopFlexliteChair.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import CampChairsPic from '../../../images/CampChairs.jpg';


function REICoopFlexliteChair () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for REI Co-op Flexlite Chair</h1>
          Average Rating (4.5) <Rating name="read-only" value={4.5} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.rei.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampChairsPic} style={{height:'120px'}} alt="CampChairs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="REI Co-op Flexlite Chair" height="130px" src={REICoopFlexliteChairPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users love the portability of the Flexlite Chair, making it a go-to for quick setups at campsites. Many highlight its comfort level and design stability, even on uneven ground. Some find the price to be high, but they feel the investment pays off in quality.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Lightweight and easy to carry</li>
<li className="ListItemStyle">Comfortable and supportive</li>
<li className="ListItemStyle">Quick and easy assembly</li>
<li className="ListItemStyle">Stable on various terrains</li>
<li className="ListItemStyle">Attractive design</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Price may be a barrier for some</li>
<li className="ListItemStyle">Less cushioned than traditional chairs</li>
<li className="ListItemStyle">Requires careful assembly</li>
<li className="ListItemStyle">Limited seat height</li>
<li className="ListItemStyle">Not for very heavy users</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Lightweight and easy to carry</li>
<li className="ListItemStyle">Comfortable and supportive</li>
<li className="ListItemStyle">Quick and easy assembly</li>
<li className="ListItemStyle">Stable on various terrains</li>
<li className="ListItemStyle">Attractive design</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Price may be a barrier for some</li>
<li className="ListItemStyle">Less cushioned than traditional chairs</li>
<li className="ListItemStyle">Requires careful assembly</li>
<li className="ListItemStyle">Limited seat height</li>
<li className="ListItemStyle">Not for very heavy users</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default REICoopFlexliteChair;

