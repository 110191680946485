import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import CampStovesPic from '../images/CampStoves.jpg';

//import CampChef2BurnerCampStovePic from './images/CampChef2BurnerCampStove.png';
//import CampChefEverestCampStovePic from './images/CampChefEverestCampStove.png';
//import ColemanClassicPropaneStovePic from './images/ColemanClassicPropaneStove.png';
//import EurekaIgnitePlusCampStovePic from './images/EurekaIgnitePlusCampStove.png';
//import PrimusClassicTrailStovePic from './images/PrimusClassicTrailStove.png';


function CampStoves () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.campchef.com/",
      //imageAlt:"Camp Chef 2 Burner Camp Stove",
      //imageRef:CampChef2BurnerCampStovePic,
      id:"CampChef2BurnerCampStove",
      itemLink:"../Camping/CampStoves/Reviews/CampChef2BurnerCampStove",
      itemBoldText:"Camp Chef 2 Burner Camp Stove",
      itemText:"The Camp Chef 2 Burner Camp Stove offers high performance with two powerful burners for optimal cooking while camping. It features adjustable heat controls, a durable cooking surface, and a strong yet lightweight design, perfect for outdoor cooking enthusiasts.",
      averageRating:4.7
    },
	    {
      manuLink:"https://www.campchef.com/",
      //imageAlt:"Camp Chef Everest Camp Stove",
      //imageRef:CampChefEverestCampStovePic,
      id:"CampChefEverestCampStove",
      itemLink:"../Camping/CampStoves/Reviews/CampChefEverestCampStove",
      itemBoldText:"Camp Chef Everest Camp Stove",
      itemText:"The Camp Chef Everest Camp Stove is a high-performance two-burner outdoor stove, praised for its rapid boil time and sturdy build. Featuring precise heat controls and a compact foldable design, it's perfect for both camping and tailgating.",
      averageRating:4.9
    },
	    {
      manuLink:"https://www.coleman.com",
      //imageAlt:"Coleman Classic Propane Stove",
      //imageRef:ColemanClassicPropaneStovePic,
      id:"ColemanClassicPropaneStove",
      itemLink:"../Camping/CampStoves/Reviews/ColemanClassicPropaneStove",
      itemBoldText:"Coleman Classic Propane Stove",
      itemText:"The Coleman Classic Propane Stove is a reliable and straightforward camping stove that features two adjustable burners, offering ample cooking power for various meals. It is compact and easy to transport, making it an ideal choice for camping trips.",
      averageRating:4.5
    },
	    {
      manuLink:"https://eurekacamping.com/",
      //imageAlt:"Eureka! Ignite Plus Camp Stove",
      //imageRef:EurekaIgnitePlusCampStovePic,
      id:"EurekaIgnitePlusCampStove",
      itemLink:"../Camping/CampStoves/Reviews/EurekaIgnitePlusCampStove",
      itemBoldText:"Eureka! Ignite Plus Camp Stove",
      itemText:"The Eureka! Ignite Plus Camp Stove is designed for outdoor cooking aficionados, offering precision cooking with duel adjustable burners and a compact design. The stove features a built-in igniter, durable materials for longevity, and excellent stability.",
      averageRating:4.6
    },
	    {
      manuLink:"https://www.primus.us/",
      //imageAlt:"Primus Classic Trail Stove",
      //imageRef:PrimusClassicTrailStovePic,
      id:"PrimusClassicTrailStove",
      itemLink:"../Camping/CampStoves/Reviews/PrimusClassicTrailStove",
      itemBoldText:"Primus Classic Trail Stove",
      itemText:"The Primus Classic Trail Stove is a compact and versatile camping stove designed for easy use and portability. Ideal for backpacking, it boasts a powerful burner with adjustable flame settings and a lightweight build.",
      averageRating:4.4
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top Camp Stoves for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={CampStovesPic} style={{height:'120px'}} alt="Camp Stoves"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Cooking outdoors is special. Whether it is making breakfast first thing in the morning or cooking steaks at night. Very few things are as relaxing as cooking outdoors. Having the right camp stove can make the difference between having a relaxing or frustrating time. Here are some camp stoves that have made it to the top of the list.

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default CampStoves;

