import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import SigSauerRomeo5Pic from '../images/SigSauerRomeo5.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import RedDotSightsPic from '../../../images/RedDotSights.jpg';


function SigSauerRomeo5 () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Sig Sauer Romeo 5</h1>
          Average Rating (4.5) <Rating name="read-only" value={4.5} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.sigsauer.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ RedDotSightsPic} style={{height:'120px'}} alt="RedDotSights"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Sig Sauer Romeo 5" height="130px" src={SigSauerRomeo5Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Customers often highlight the bright dot and the ease of adjustment for different shooting styles, making it a hit among both new and seasoned shooters. Many find the battery compartment to be user-friendly, allowing quick changes without losing zero. Users also appreciate how lightweight this sight is, enhancing the overall handling of their firearm.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Very affordable</li>
<li className="ListItemStyle">Multiple reticle options</li>
<li className="ListItemStyle">Durable and shockproof</li>
<li className="ListItemStyle">Long battery life with auto shut-off</li>
<li className="ListItemStyle">Easy to use and adjust</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Some users report issues with lens clarity</li>
<li className="ListItemStyle">Mount can be less secure on certain rails</li>
<li className="ListItemStyle">Dot may wash out in bright sunlight</li>
<li className="ListItemStyle">Limited magnification options</li>
<li className="ListItemStyle">Less suitable for long-range shooting</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Very affordable</li>
<li className="ListItemStyle">Multiple reticle options</li>
<li className="ListItemStyle">Durable and shockproof</li>
<li className="ListItemStyle">Long battery life with auto shut-off</li>
<li className="ListItemStyle">Easy to use and adjust</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Some users report issues with lens clarity</li>
<li className="ListItemStyle">Mount can be less secure on certain rails</li>
<li className="ListItemStyle">Dot may wash out in bright sunlight</li>
<li className="ListItemStyle">Limited magnification options</li>
<li className="ListItemStyle">Less suitable for long-range shooting</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default SigSauerRomeo5;

