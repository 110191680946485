import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import CampChairsPic from '../images/CampChairs.jpg';

//import ALPSMountaineeringKingKongChairPic from './images/ALPSMountaineeringKingKongChair.png';
//import ColemanPortableCampingQuadChairPic from './images/ColemanPortableCampingQuadChair.png';
//import GCIOutdoorFreestyleRockerPic from './images/GCIOutdoorFreestyleRocker.png';
//import HelinoxChairOnePic from './images/HelinoxChairOne.png';
//import REICoopFlexliteChairPic from './images/REICoopFlexliteChair.png';


function CampChairs () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.alpsbrands.com/",
      //imageAlt:"ALPS Mountaineering King Kong Chair",
      //imageRef:ALPSMountaineeringKingKongChairPic,
      id:"ALPSMountaineeringKingKongChair",
      itemLink:"../Camping/CampChairs/Reviews/ALPSMountaineeringKingKongChair",
      itemBoldText:"ALPS Mountaineering King Kong Chair",
      itemText:"The ALPS Mountaineering King Kong Chair offers extra wide seating with robust construction, making it a favorite for camping and tailgating. It features multiple pockets, including cup holders and side storage, amplifying convenience.",
      averageRating:4.7
    },
	    {
      manuLink:"https://www.coleman.com",
      //imageAlt:"Coleman Portable Camping Quad Chair",
      //imageRef:ColemanPortableCampingQuadChairPic,
      id:"ColemanPortableCampingQuadChair",
      itemLink:"../Camping/CampChairs/Reviews/ColemanPortableCampingQuadChair",
      itemBoldText:"Coleman Portable Camping Quad Chair",
      itemText:"The Coleman Portable Camping Quad Chair is a sturdy and comfortable seating option for outdoor activities. It features a durable frame, breathable mesh back, and cup holder, making it perfect for camping, tailgating, or picnics.",
      averageRating:4.5
    },
	    {
      manuLink:"https://gcioutdoor.com",
      //imageAlt:"GCI Outdoor Freestyle Rocker",
      //imageRef:GCIOutdoorFreestyleRockerPic,
      id:"GCIOutdoorFreestyleRocker",
      itemLink:"../Camping/CampChairs/Reviews/GCIOutdoorFreestyleRocker",
      itemBoldText:"GCI Outdoor Freestyle Rocker",
      itemText:"The GCI Outdoor Freestyle Rocker features a unique design that allows for smooth rocking motion, combining the comfort of a rocking chair with portability. It is an excellent option for camping, festivals, or backyard use.",
      averageRating:4.4
    },
	    {
      manuLink:"https://helinox.com/",
      //imageAlt:"Helinox Chair One",
      //imageRef:HelinoxChairOnePic,
      id:"HelinoxChairOne",
      itemLink:"../Camping/CampChairs/Reviews/HelinoxChairOne",
      itemBoldText:"Helinox Chair One",
      itemText:"The Helinox Chair One is a lightweight, portable camp chair that folds down into a compact size, making it an ideal choice for hiking and backpacking trips. It offers excellent support and comfort with its ergonomic design.",
      averageRating:4.6
    },
	    {
      manuLink:"https://www.rei.com/",
      //imageAlt:"REI Co-op Flexlite Chair",
      //imageRef:REICoopFlexliteChairPic,
      id:"REICoopFlexliteChair",
      itemLink:"../Camping/CampChairs/Reviews/REICoopFlexliteChair",
      itemBoldText:"REI Co-op Flexlite Chair",
      itemText:"The REI Co-op Flexlite Chair is a lightweight and compact chair designed for comfort, making it perfect for camping and outdoor events. Its durable materials and design provide a balance of support and ease of transport.",
      averageRating:4.5
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top Camp Chairs for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={CampChairsPic} style={{height:'120px'}} alt="Camp Chairs"></img>
        </td>
      </tr>
      </tbody>
    </table>

Very few things are as peaceful as sitting around a campfire in your favorite chair with a group of friends. Here are some camp chairs that can help add to that experience.

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default CampChairs;

