import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MysteryRanchMetcalfPic from '../images/MysteryRanchMetcalf.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import HuntingPacksPic from '../../../images/HuntingPacks.jpg';


function MysteryRanchMetcalf () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Mystery Ranch Metcalf</h1>
          Average Rating (4.7) <Rating name="read-only" value={4.7} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.mysteryranch.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingPacksPic} style={{height:'120px'}} alt="HuntingPacks"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Mystery Ranch Metcalf" height="130px" src={MysteryRanchMetcalfPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Many users rave about the Metcalf's comfort and adjustability, noting the effective weight distribution even on long hikes. Others appreciate the large capacity, making it suitable for multi-day trips while still being able to pack efficiently. However, a few found the price point to be on the higher side, but most agree the quality justifies the investment.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Exceptional comfort and load distribution</li>
<li className="ListItemStyle">Durable and weather-resistant materials</li>
<li className="ListItemStyle">Ample storage capacity with organized compartments</li>
<li className="ListItemStyle">Adjustable harness for a custom fit</li>
<li className="ListItemStyle">Versatile design for various hunting situations</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to similar packs</li>
<li className="ListItemStyle">Heavier than some alternatives</li>
<li className="ListItemStyle">May require extra accessories for optimal organization</li>
<li className="ListItemStyle">Limited color options</li>
<li className="ListItemStyle">Can be overkill for short day trips</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Exceptional comfort and load distribution</li>
<li className="ListItemStyle">Durable and weather-resistant materials</li>
<li className="ListItemStyle">Ample storage capacity with organized compartments</li>
<li className="ListItemStyle">Adjustable harness for a custom fit</li>
<li className="ListItemStyle">Versatile design for various hunting situations</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to similar packs</li>
<li className="ListItemStyle">Heavier than some alternatives</li>
<li className="ListItemStyle">May require extra accessories for optimal organization</li>
<li className="ListItemStyle">Limited color options</li>
<li className="ListItemStyle">Can be overkill for short day trips</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default MysteryRanchMetcalf;

