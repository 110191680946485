import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import VortexOpticsVenomRedDotSightPic from '../images/VortexOpticsVenomRedDotSight.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import RedDotSightsPic from '../../../images/RedDotSights.jpg';


function VortexOpticsVenomRedDotSight () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Vortex Optics Venom Red Dot Sight</h1>
          Average Rating (4.7) <Rating name="read-only" value={4.7} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://vortexoptics.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ RedDotSightsPic} style={{height:'120px'}} alt="RedDotSights"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Vortex Optics Venom Red Dot Sight" height="130px" src={VortexOpticsVenomRedDotSightPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users frequently praise the Vortex Venom for its clear optics and ease of use, making it an ideal choice for both beginners and experienced shooters. Many appreciate the solid construction and reliable performance, often highlighting how well it holds zero even after extensive use. Some reviews also note the excellent battery life, which adds to the overall convenience of the sight.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Excellent clarity and brightness options</li>
<li className="ListItemStyle">Durable construction</li>
<li className="ListItemStyle">Long battery life</li>
<li className="ListItemStyle">Wide field of view</li>
<li className="ListItemStyle">Easy to mount and adjust</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Some users report concerns with lens fogging</li>
<li className="ListItemStyle">Mounting options may be limited</li>
<li className="ListItemStyle">Higher price point compared to competitors</li>
<li className="ListItemStyle">Limited warranty actual response time</li>
<li className="ListItemStyle">Battery compartment can be tricky to access</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Excellent clarity and brightness options</li>
<li className="ListItemStyle">Durable construction</li>
<li className="ListItemStyle">Long battery life</li>
<li className="ListItemStyle">Wide field of view</li>
<li className="ListItemStyle">Easy to mount and adjust</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Some users report concerns with lens fogging</li>
<li className="ListItemStyle">Mounting options may be limited</li>
<li className="ListItemStyle">Higher price point compared to competitors</li>
<li className="ListItemStyle">Limited warranty actual response time</li>
<li className="ListItemStyle">Battery compartment can be tricky to access</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default VortexOpticsVenomRedDotSight;

