import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import EurekaIgnitePlusCampStovePic from '../images/EurekaIgnitePlusCampStove.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import CampStovesPic from '../../../images/CampStoves.jpg';


function EurekaIgnitePlusCampStove () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Eureka! Ignite Plus Camp Stove</h1>
          Average Rating (4.6) <Rating name="read-only" value={4.6} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://eurekacamping.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampStovesPic} style={{height:'120px'}} alt="CampStoves"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Eureka! Ignite Plus Camp Stove" height="130px" src={EurekaIgnitePlusCampStovePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Reviewers rave about the Eureka! Ignite Plus for its incredible stability and powerful burners, allowing them to cook various meals efficiently. Many users value the built-in igniter, which makes lighting the stove quick and easy. However, some have noted that the stove can be a bit bulky for backpacking, but it excels for car camping.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Excellent burner power and heat output</li>
<li className="ListItemStyle">Built-in igniter for easy lighting</li>
<li className="ListItemStyle">Sturdy construction</li>
<li className="ListItemStyle">Compact design for easy transport</li>
<li className="ListItemStyle">Great stability even on uneven ground</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Bulkier than traditional backpacking stoves</li>
<li className="ListItemStyle">Can be heavier than competitor models</li>
<li className="ListItemStyle">Higher price point than basic models</li>
<li className="ListItemStyle">Requires propane for use</li>
<li className="ListItemStyle">May have limited availability in retail stores</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Excellent burner power and heat output</li>
<li className="ListItemStyle">Built-in igniter for easy lighting</li>
<li className="ListItemStyle">Sturdy construction</li>
<li className="ListItemStyle">Compact design for easy transport</li>
<li className="ListItemStyle">Great stability even on uneven ground</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Bulkier than traditional backpacking stoves</li>
<li className="ListItemStyle">Can be heavier than competitor models</li>
<li className="ListItemStyle">Higher price point than basic models</li>
<li className="ListItemStyle">Requires propane for use</li>
<li className="ListItemStyle">May have limited availability in retail stores</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default EurekaIgnitePlusCampStove;

