import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BaofengBFF8HPPic from '../images/BaofengBFF8HP.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import TwoWayRadiosPic from '../../../images/TwoWayRadios.jpg';


function BaofengBFF8HP () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Baofeng BF-F8HP</h1>
          Average Rating (4.6) <Rating name="read-only" value={4.6} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.baofengtech.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ TwoWayRadiosPic} style={{height:'120px'}} alt="2WayRadios"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Baofeng BF-F8HP" height="130px" src={BaofengBFF8HPPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users enjoy the Baofeng BF-F8HP for its range and power, especially in areas with challenging terrain. The customization options are a plus for tech-savvy users, although some found the learning curve steep. A few users noted that the audio quality is generally good, but can be sensitive to background noise.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High power output</li>
<li className="ListItemStyle">Customizable frequencies</li>
<li className="ListItemStyle">Great range for its category</li>
<li className="ListItemStyle">Solid construction</li>
<li className="ListItemStyle">Ideal for tech enthusiasts</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Steeper learning curve</li>
<li className="ListItemStyle">Not as user-friendly</li>
<li className="ListItemStyle">Battery life may vary with usage</li>
<li className="ListItemStyle">Can pick up background noise</li>
<li className="ListItemStyle">Bulkier than other models</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High power output</li>
<li className="ListItemStyle">Customizable frequencies</li>
<li className="ListItemStyle">Great range for its category</li>
<li className="ListItemStyle">Solid construction</li>
<li className="ListItemStyle">Ideal for tech enthusiasts</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Steeper learning curve</li>
<li className="ListItemStyle">Not as user-friendly</li>
<li className="ListItemStyle">Battery life may vary with usage</li>
<li className="ListItemStyle">Can pick up background noise</li>
<li className="ListItemStyle">Bulkier than other models</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default BaofengBFF8HP;

