import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import CelestronNatureDXPic from '../images/CelestronNatureDX.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import BinocularsPic from '../../../images/Binoculars.jpg';


function CelestronNatureDX () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Celestron Nature DX</h1>
          Average Rating (4.4) <Rating name="read-only" value={4.4} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.celestron.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ BinocularsPic} style={{height:'120px'}} alt="Binoculars"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Celestron Nature DX" height="130px" src={CelestronNatureDXPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Many users appreciate the Celestron Nature DX for its affordability while still providing excellent optical performance. One enthusiastic reviewer mentioned how these binoculars have transformed their birdwatching experiences, allowing them to spot details they previously missed. Additionally, the lightweight design gets a lot of praise, particularly from users venturing on long hikes.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Affordable price</li>
<li className="ListItemStyle">Lightweight and portable</li>
<li className="ListItemStyle">Good optical clarity</li>
<li className="ListItemStyle">Waterproof design</li>
<li className="ListItemStyle">Durable construction</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited magnification options</li>
<li className="ListItemStyle">Some users reported issues with the diopter adjustment</li>
<li className="ListItemStyle">Field of view not as wide as others</li>
<li className="ListItemStyle">Not ideal for low-light conditions</li>
<li className="ListItemStyle">Carrying case quality could improve</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Affordable price</li>
<li className="ListItemStyle">Lightweight and portable</li>
<li className="ListItemStyle">Good optical clarity</li>
<li className="ListItemStyle">Waterproof design</li>
<li className="ListItemStyle">Durable construction</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited magnification options</li>
<li className="ListItemStyle">Some users reported issues with the diopter adjustment</li>
<li className="ListItemStyle">Field of view not as wide as others</li>
<li className="ListItemStyle">Not ideal for low-light conditions</li>
<li className="ListItemStyle">Carrying case quality could improve</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default CelestronNatureDX;

