import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import SleepingBagsPic from '../images/SleepingBags.jpg';

//import ColemanSleepingBagforKidsPic from './images/ColemanSleepingBagforKids.png';
//import KeltyCosmic20SleepingBagPic from './images/KeltyCosmic20SleepingBag.png';
//import NorthFaceBlueKazooSleepingBagPic from './images/NorthFaceBlueKazooSleepingBag.png';
//import REICoopMagma15SleepingBagPic from './images/REICoopMagma15SleepingBag.png';
//import TetonSportsCelsiusSleepingBagPic from './images/TetonSportsCelsiusSleepingBag.png';


function SleepingBags () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.coleman.com/",
      //imageAlt:"Coleman Sleeping Bag for Kids",
      //imageRef:ColemanSleepingBagforKidsPic,
      id:"ColemanSleepingBagforKids",
      itemLink:"../Camping/SleepingBags/Reviews/ColemanSleepingBagforKids",
      itemBoldText:"Coleman Sleeping Bag for Kids",
      itemText:"This Coleman Sleeping Bag is specially designed for kids, featuring fun, colorful designs that appeal to younger campers. Its temperature rating keeps kids warm without being too constricting, and it comes with built-in insulation for a cozy night's sleep.",
      averageRating:4.5
    },
	    {
      manuLink:"https://www.kelty.com/",
      //imageAlt:"Kelty Cosmic 20 Sleeping Bag",
      //imageRef:KeltyCosmic20SleepingBagPic,
      id:"KeltyCosmic20SleepingBag",
      itemLink:"../Camping/SleepingBags/Reviews/KeltyCosmic20SleepingBag",
      itemBoldText:"Kelty Cosmic 20 Sleeping Bag",
      itemText:"The Kelty Cosmic 20 Sleeping Bag is a reliable option for cold weather camping, featuring synthetic insulation that maintains warmth when wet. Its rugged exterior and thoughtful design make it an excellent choice for backpackers.",
      averageRating:4.4
    },
	    {
      manuLink:"https://www.thenorthface.com",
      //imageAlt:"North Face Blue Kazoo Sleeping Bag",
      //imageRef:NorthFaceBlueKazooSleepingBagPic,
      id:"NorthFaceBlueKazooSleepingBag",
      itemLink:"../Camping/SleepingBags/Reviews/NorthFaceBlueKazooSleepingBag",
      itemBoldText:"North Face Blue Kazoo Sleeping Bag",
      itemText:"The North Face Blue Kazoo Sleeping Bag is designed for 3-season use, featuring high-quality down insulation for exceptional warmth-to-weight ratio. It provides excellent packability and comfort, with a draft collar and hood to keep the cold out.",
      averageRating:4.7
    },
	    {
      manuLink:"https://www.rei.com",
      //imageAlt:"REI Co-op Magma 15 Sleeping Bag",
      //imageRef:REICoopMagma15SleepingBagPic,
      id:"REICoopMagma15SleepingBag",
      itemLink:"../Camping/SleepingBags/Reviews/REICoopMagma15SleepingBag",
      itemBoldText:"REI Co-op Magma 15 Sleeping Bag",
      itemText:"The REI Co-op Magma 15 Sleeping Bag is crafted for backpacking in cold weather. With premium down insulation and a water-resistant shell, it offers outstanding warmth and compactness without sacrificing comfort.",
      averageRating:4.8
    },
	    {
      manuLink:"https://tetonsports.com",
      //imageAlt:"Teton Sports Celsius Sleeping Bag",
      //imageRef:TetonSportsCelsiusSleepingBagPic,
      id:"TetonSportsCelsiusSleepingBag",
      itemLink:"../Camping/SleepingBags/Reviews/TetonSportsCelsiusSleepingBag",
      itemBoldText:"Teton Sports Celsius Sleeping Bag",
      itemText:"Teton Sports Celsius is a versatile sleeping bag that combines affordability with performance. Designed for temperatures as low as 0°F, it features a unique design that allows for flexibility in use, whether in a tent or on the ground.",
      averageRating:4.6
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top Sleeping Bags for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={SleepingBagsPic} style={{height:'120px'}} alt="Sleeping Bags"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Have you ever been spending the night outdoors without a sleeping bag warm enough for the cold? I have, and it isn’t very much fun. Sleeping bags are a must have when sleeping outdoors, even in the summer in some parts of the world. Below are some great options for keeping you warm on those cold nights.

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default SleepingBags;

