import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MagellaneXplorist310Pic from '../images/MagellaneXplorist310.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import HandheldGPSPic from '../../../images/HandheldGPS.jpg';


function MagellaneXplorist310 () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Magellan eXplorist 310</h1>
          Average Rating (4.4) <Rating name="read-only" value={4.4} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.magellangps.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ HandheldGPSPic} style={{height:'120px'}} alt="HandheldGPS"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Magellan eXplorist 310" height="130px" src={MagellaneXplorist310Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users commend the eXplorist 310 for its intuitive layout and clear display, making it easy to follow routes during outdoor adventures. The geocaching features have also garnered positive feedback, proving useful for users who enjoy treasure hunting. Nevertheless, some have indicated that the device can be slow to lock onto satellites.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Intuitive user interface</li>
<li className="ListItemStyle">Clear color display</li>
<li className="ListItemStyle">Excellent geocaching features</li>
<li className="ListItemStyle">Rugged and waterproof design</li>
<li className="ListItemStyle">Affordable for features offered</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Slow satellite lock-on times</li>
<li className="ListItemStyle">Limited memory for additional maps</li>
<li className="ListItemStyle">Occasional software glitches</li>
<li className="ListItemStyle">Battery life could be improved</li>
<li className="ListItemStyle">Screen visibility issues in bright light</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Intuitive user interface</li>
<li className="ListItemStyle">Clear color display</li>
<li className="ListItemStyle">Excellent geocaching features</li>
<li className="ListItemStyle">Rugged and waterproof design</li>
<li className="ListItemStyle">Affordable for features offered</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Slow satellite lock-on times</li>
<li className="ListItemStyle">Limited memory for additional maps</li>
<li className="ListItemStyle">Occasional software glitches</li>
<li className="ListItemStyle">Battery life could be improved</li>
<li className="ListItemStyle">Screen visibility issues in bright light</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default MagellaneXplorist310;

