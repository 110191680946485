import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import VortexOpticsDiamondbackHDPic from '../images/VortexOpticsDiamondbackHD.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import BinocularsPic from '../../../images/Binoculars.jpg';


function VortexOpticsDiamondbackHD () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Vortex Optics Diamondback HD</h1>
          Average Rating (4.8) <Rating name="read-only" value={4.8} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://vortexoptics.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ BinocularsPic} style={{height:'120px'}} alt="Binoculars"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Vortex Optics Diamondback HD" height="130px" src={VortexOpticsDiamondbackHDPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users rave about the Diamondback HD for its outstanding image clarity and ease of use; one reviewer noted that they were impressed with the brightness in low-light conditions during early morning hikes. Another customer praised the ergonomic design, making it comfortable to hold for extended periods. Additionally, several users highlighted the durable construction, making these binoculars suitable for various outdoor adventures, from birdwatching to hunting.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Excellent image clarity</li>
<li className="ListItemStyle">Lightweight and portable</li>
<li className="ListItemStyle">Durable and rugged design</li>
<li className="ListItemStyle">Great low-light performance</li>
<li className="ListItemStyle">Comfortable to hold</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Slightly higher price point</li>
<li className="ListItemStyle">Limited field of view compared to competitors</li>
<li className="ListItemStyle">Some users report minor focus adjustment issues</li>
<li className="ListItemStyle">No carrying case included</li>
<li className="ListItemStyle">Eye relief may not be suitable for all users</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Excellent image clarity</li>
<li className="ListItemStyle">Lightweight and portable</li>
<li className="ListItemStyle">Durable and rugged design</li>
<li className="ListItemStyle">Great low-light performance</li>
<li className="ListItemStyle">Comfortable to hold</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Slightly higher price point</li>
<li className="ListItemStyle">Limited field of view compared to competitors</li>
<li className="ListItemStyle">Some users report minor focus adjustment issues</li>
<li className="ListItemStyle">No carrying case included</li>
<li className="ListItemStyle">Eye relief may not be suitable for all users</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default VortexOpticsDiamondbackHD;

