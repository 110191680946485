import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import TrailCamsPic from '../images/TrailCams.jpg';

//import BrowningStrikeForceHDProPic from './images/BrowningStrikeForceHDPro.png';
//import BushnellTrophyCamHDPic from './images/BushnellTrophyCamHD.png';
//import MoultrieA40iPic from './images/MoultrieA40i.png';
//import StealthCamG36NGPic from './images/StealthCamG36NG.png';
//import WildgameInnovationsTerra14Pic from './images/WildgameInnovationsTerra14.png';


function TrailCams () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.browning.com",
      //imageAlt:"Browning Strike Force HD Pro",
      //imageRef:BrowningStrikeForceHDProPic,
      id:"BrowningStrikeForceHDPro",
      itemLink:"../Electronics/TrailCams/Reviews/BrowningStrikeForceHDPro",
      itemBoldText:"Browning Strike Force HD Pro",
      itemText:"The Browning Strike Force HD Pro is a compact trail camera known for its high-resolution images and quick trigger speed, making it ideal for capturing wildlife activity day and night.",
      averageRating:4.5
    },
	    {
      manuLink:"https://www.bushnell.com/",
      //imageAlt:"Bushnell Trophy Cam HD",
      //imageRef:BushnellTrophyCamHDPic,
      id:"BushnellTrophyCamHD",
      itemLink:"../Electronics/TrailCams/Reviews/BushnellTrophyCamHD",
      itemBoldText:"Bushnell Trophy Cam HD",
      itemText:"The Bushnell Trophy Cam HD is known for its rugged design and excellent image quality, providing 14 MP images and a fast trigger speed, making it a reliable choice for outdoor enthusiasts.",
      averageRating:4.6
    },
	    {
      manuLink:"https://www.moultriefeeders.com/",
      //imageAlt:"Moultrie A-40i",
      //imageRef:MoultrieA40iPic,
      id:"MoultrieA40i",
      itemLink:"../Electronics/TrailCams/Reviews/MoultrieA40i",
      itemBoldText:"Moultrie A-40i",
      itemText:"The Moultrie A-40i offers a great balance of performance and price, featuring 14 MP resolution and an integrated viewer for quick image checks, making it a favorite among casual users.",
      averageRating:4.3
    },
	    {
      manuLink:"https://www.stealthcam.com/",
      //imageAlt:"Stealth Cam G36NG",
      //imageRef:StealthCamG36NGPic,
      id:"StealthCamG36NG",
      itemLink:"../Electronics/TrailCams/Reviews/StealthCamG36NG",
      itemBoldText:"Stealth Cam G36NG",
      itemText:"The Stealth Cam G36NG is designed with No Glow technology to ensure stealthy operation, delivering 12 MP images and excellent video quality, along with a variety of customizable settings.",
      averageRating:4.2
    },
	    {
      manuLink:"https://wildgameinnovations.com",
      //imageAlt:"Wildgame Innovations Terra 14",
      //imageRef:WildgameInnovationsTerra14Pic,
      id:"WildgameInnovationsTerra14",
      itemLink:"../Electronics/TrailCams/Reviews/WildgameInnovationsTerra14",
      itemBoldText:"Wildgame Innovations Terra 14",
      itemText:"The Wildgame Innovations Terra 14 packs a punch with its 14 MP capability and affordability, making it accessible for those just starting with trail cameras without sacrificing quality.",
      averageRating:4.1
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top Trail Cams for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={TrailCamsPic} style={{height:'120px'}} alt="Trail Cams"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Do you want to see what is going on at your hunting spot when you are not around? Trail cams can do just that. Whether you are trying to capture that big buck or just want to see what type of critters are roaming around, a trail cam is a must. Below are some great choices.

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default TrailCams;

