import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import KeltyCosmic20SleepingBagPic from '../images/KeltyCosmic20SleepingBag.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import SleepingBagsPic from '../../../images/SleepingBags.jpg';


function KeltyCosmic20SleepingBag () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Kelty Cosmic 20 Sleeping Bag</h1>
          Average Rating (4.4) <Rating name="read-only" value={4.4} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.kelty.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ SleepingBagsPic} style={{height:'120px'}} alt="SleepingBags"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Kelty Cosmic 20 Sleeping Bag" height="130px" src={KeltyCosmic20SleepingBagPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Outdoor enthusiasts appreciate the Kelty Cosmic for its excellent warmth-to-weight ratio, specifically for its synthetic insulation that performs well in wet conditions. Many find the price reasonable given its quality, and the design's versatility is often praised for both backpacking and camping trips. However, a few users have noted that it can feel a bit constricted for larger individuals.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Synthetic insulation for wet conditions</li>
<li className="ListItemStyle">Good warmth retention</li>
<li className="ListItemStyle">Versatile for various uses</li>
<li className="ListItemStyle">Reasonably priced</li>
<li className="ListItemStyle">Durable and robust materials</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavy for long backpacking trips</li>
<li className="ListItemStyle">Can feel tight for larger users</li>
<li className="ListItemStyle">Not as compressible as down bags</li>
<li className="ListItemStyle">Zipper issues reported</li>
<li className="ListItemStyle">Limited temperature range compared to others</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Synthetic insulation for wet conditions</li>
<li className="ListItemStyle">Good warmth retention</li>
<li className="ListItemStyle">Versatile for various uses</li>
<li className="ListItemStyle">Reasonably priced</li>
<li className="ListItemStyle">Durable and robust materials</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavy for long backpacking trips</li>
<li className="ListItemStyle">Can feel tight for larger users</li>
<li className="ListItemStyle">Not as compressible as down bags</li>
<li className="ListItemStyle">Zipper issues reported</li>
<li className="ListItemStyle">Limited temperature range compared to others</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default KeltyCosmic20SleepingBag;

