import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";

import JMOMenu from "./components/jmomenu.js";
/* import Layout from "./components/Layout.js"; */
// import FloatingDiv from "./components/FloatingDiv.js"

import CampStoves from "./pages/Camping/CampStoves.js";
import CampChef2BurnerCampStove from "./pages/Camping/CampStoves/Reviews/CampChef2BurnerCampStove.js";
import CampChefEverestCampStove from "./pages/Camping/CampStoves/Reviews/CampChefEverestCampStove.js";
import ColemanClassicPropaneStove from "./pages/Camping/CampStoves/Reviews/ColemanClassicPropaneStove.js";
import EurekaIgnitePlusCampStove from "./pages/Camping/CampStoves/Reviews/EurekaIgnitePlusCampStove.js";
import PrimusClassicTrailStove from "./pages/Camping/CampStoves/Reviews/PrimusClassicTrailStove.js";

import CampChairs from "./pages/Camping/CampChairs.js";
import ALPSMountaineeringKingKongChair from "./pages/Camping/CampChairs/Reviews/ALPSMountaineeringKingKongChair.js";
import ColemanPortableCampingQuadChair from "./pages/Camping/CampChairs/Reviews/ColemanPortableCampingQuadChair.js";
import GCIOutdoorFreestyleRocker from "./pages/Camping/CampChairs/Reviews/GCIOutdoorFreestyleRocker.js";
import HelinoxChairOne from "./pages/Camping/CampChairs/Reviews/HelinoxChairOne.js";
import REICoopFlexliteChair from "./pages/Camping/CampChairs/Reviews/REICoopFlexliteChair.js";

import FireStarters from "./pages/Camping/FireStarters.js";
import BICLighter from "./pages/Camping/FireStarters/Reviews/BICLighter.js";
import FireStarterSticksbyCoghlans from "./pages/Camping/FireStarters/Reviews/FireStarterSticksbyCoghlans.js";
import UCOStormproofMatches from "./pages/Camping/FireStarters/Reviews/UCOStormproofMatches.js";

import SleepingBags from "./pages/Camping/SleepingBags.js";
import ColemanSleepingBagforKids from "./pages/Camping/SleepingBags/Reviews/ColemanSleepingBagforKids.js";
import KeltyCosmic20SleepingBag from "./pages/Camping/SleepingBags/Reviews/KeltyCosmic20SleepingBag.js";
import NorthFaceBlueKazooSleepingBag from "./pages/Camping/SleepingBags/Reviews/NorthFaceBlueKazooSleepingBag.js";
import REICoopMagma15SleepingBag from "./pages/Camping/SleepingBags/Reviews/REICoopMagma15SleepingBag.js";
import TetonSportsCelsiusSleepingBag from "./pages/Camping/SleepingBags/Reviews/TetonSportsCelsiusSleepingBag.js";

import HandheldGPS from "./pages/Electronics/HandheldGPS.js";
import GarmineTrex32x from "./pages/Electronics/HandheldGPS/Reviews/GarmineTrex32x.js";
import GarminGPSMAP66i from "./pages/Electronics/HandheldGPS/Reviews/GarminGPSMAP66i.js";
import GarminOregon700 from "./pages/Electronics/HandheldGPS/Reviews/GarminOregon700.js";
import LowranceiFinderHuntC from "./pages/Electronics/HandheldGPS/Reviews/LowranceiFinderHuntC.js";
import MagellaneXplorist310 from "./pages/Electronics/HandheldGPS/Reviews/MagellaneXplorist310.js";

import SatelliteCommunicators from "./pages/Electronics/SatelliteCommunicators.js";
import GarmininReachMini2 from "./pages/Electronics/SatelliteCommunicators/Reviews/GarmininReachMini2.js";
import InReachSESatelliteCommunicator from "./pages/Electronics/SatelliteCommunicators/Reviews/InReachSESatelliteCommunicator.js";
import IridiumExtreme9575 from "./pages/Electronics/SatelliteCommunicators/Reviews/IridiumExtreme9575.js";
import SpotGen4SatelliteMessenger from "./pages/Electronics/SatelliteCommunicators/Reviews/SpotGen4SatelliteMessenger.js";
import ZoleoSatelliteCommunicator from "./pages/Electronics/SatelliteCommunicators/Reviews/ZoleoSatelliteCommunicator.js";

import TwoWayRadios from "./pages/Electronics/TwoWayRadios.js";
import BaofengBFF8HP from "./pages/Electronics/TwoWayRadios/Reviews/BaofengBFF8HP.js";
import KenwoodTK3402U16P from "./pages/Electronics/TwoWayRadios/Reviews/KenwoodTK3402U16P.js";
import MidlandGXT1000VP4 from "./pages/Electronics/TwoWayRadios/Reviews/MidlandGXT1000VP4.js";
import MotorolaTalkaboutT800 from "./pages/Electronics/TwoWayRadios/Reviews/MotorolaTalkaboutT800.js";
import UnidenGMR50992CKHS from "./pages/Electronics/TwoWayRadios/Reviews/UnidenGMR50992CKHS.js";

import TrailCams from "./pages/Electronics/TrailCams.js";
import BrowningStrikeForceHDPro from "./pages/Electronics/TrailCams/Reviews/BrowningStrikeForceHDPro.js";
import BushnellTrophyCamHD from "./pages/Electronics/TrailCams/Reviews/BushnellTrophyCamHD.js";
import MoultrieA40i from "./pages/Electronics/TrailCams/Reviews/MoultrieA40i.js";
import StealthCamG36NG from "./pages/Electronics/TrailCams/Reviews/StealthCamG36NG.js";
import WildgameInnovationsTerra14 from "./pages/Electronics/TrailCams/Reviews/WildgameInnovationsTerra14.js";

import HuntingKnives from "./pages/HuntingGear/HuntingKnives.js";
import BenchmadeGrizzlyRidgeFoldingKnife from "./pages/HuntingGear/HuntingKnives/Reviews/BenchmadeGrizzlyRidgeFoldingKnife.js";
import BuckKnives119Special from "./pages/HuntingGear/HuntingKnives/Reviews/BuckKnives119Special.js";
import GerberStrongArmFixedBladeKnife from "./pages/HuntingGear/HuntingKnives/Reviews/GerberStrongArmFixedBladeKnife.js";
import HavalonPirantaZFoldingKnife from "./pages/HuntingGear/HuntingKnives/Reviews/HavalonPirantaZFoldingKnife.js";
import KershawKenOnionBlurFoldingKnife from "./pages/HuntingGear/HuntingKnives/Reviews/KershawKenOnionBlurFoldingKnife.js";

import HuntingPacks from "./pages/HuntingGear/HuntingPacks.js";
import ALPSOutdoorZPursuitPack from "./pages/HuntingGear/HuntingPacks/Reviews/ALPSOutdoorZPursuitPack.js";
import Badlands2200Pack from "./pages/HuntingGear/HuntingPacks/Reviews/Badlands2200Pack.js";
import EberlestockX2Pack from "./pages/HuntingGear/HuntingPacks/Reviews/EberlestockX2Pack.js";
import KuiuUltra3000 from "./pages/HuntingGear/HuntingPacks/Reviews/KuiuUltra3000.js";
import MysteryRanchMetcalf from "./pages/HuntingGear/HuntingPacks/Reviews/MysteryRanchMetcalf.js";

import Binoculars from "./pages/Optics/Binoculars.js";
import BushnellLegendLSeries from "./pages/Optics/Binoculars/Reviews/BushnellLegendLSeries.js";
import CelestronNatureDX from "./pages/Optics/Binoculars/Reviews/CelestronNatureDX.js";
import LeupoldBX2TiogaHD from "./pages/Optics/Binoculars/Reviews/LeupoldBX2TiogaHD.js";
import NikonMonarch5 from "./pages/Optics/Binoculars/Reviews/NikonMonarch5.js";
import VortexOpticsDiamondbackHD from "./pages/Optics/Binoculars/Reviews/VortexOpticsDiamondbackHD.js";

import RedDotSights from "./pages/Optics/RedDotSights.js";
import BushnellTRS25RedDotSight from "./pages/Optics/RedDotSights/Reviews/BushnellTRS25RedDotSight.js";
import EOTechEXPS3HolographicSight from "./pages/Optics/RedDotSights/Reviews/EOTechEXPS3HolographicSight.js";
import LeupoldDeltaPointPro from "./pages/Optics/RedDotSights/Reviews/LeupoldDeltaPointPro.js";
import SigSauerRomeo5 from "./pages/Optics/RedDotSights/Reviews/SigSauerRomeo5.js";
import VortexOpticsVenomRedDotSight from "./pages/Optics/RedDotSights/Reviews/VortexOpticsVenomRedDotSight.js";

import PrivacyPolicy from "./pages/PrivacyPolicy.js";

function App() {

  window.addEventListener("orientationchange", (event) => {
    //window.location.reload(true);
    console.log(
      `the orientation of the device is now ${event.target.screen.orientation.angle}`,
    );
  });


  return (


		<BrowserRouter>
    
    <JMOMenu />
      {/* <FloatingDiv dataAdSlot='3584139985'></FloatingDiv> */}
      <Routes>
        {/* <Route index element={<Layout><Home /></Layout>}></Route>           */}
        <Route index element={<Home />}></Route>  
{/*           <Route path="about" element={<About />}></Route>
          <Route path="contact" element={<Contact />}></Route> */}

          <Route path="Camping/CampStoves" element={<CampStoves />}></Route>
          <Route path="Camping/CampStoves/Reviews/CampChef2BurnerCampStove" element={<CampChef2BurnerCampStove />}></Route>
          <Route path="Camping/CampStoves/Reviews/CampChefEverestCampStove" element={<CampChefEverestCampStove />}></Route>
          <Route path="Camping/CampStoves/Reviews/ColemanClassicPropaneStove" element={<ColemanClassicPropaneStove />}></Route>
          <Route path="Camping/CampStoves/Reviews/EurekaIgnitePlusCampStove" element={<EurekaIgnitePlusCampStove />}></Route>
          <Route path="Camping/CampStoves/Reviews/PrimusClassicTrailStove" element={<PrimusClassicTrailStove />}></Route>

          <Route path="Camping/CampChairs" element={<CampChairs />}></Route>
          <Route path="Camping/CampChairs/Reviews/ALPSMountaineeringKingKongChair" element={<ALPSMountaineeringKingKongChair />}></Route>
          <Route path="Camping/CampChairs/Reviews/ColemanPortableCampingQuadChair" element={<ColemanPortableCampingQuadChair />}></Route>
          <Route path="Camping/CampChairs/Reviews/GCIOutdoorFreestyleRocker" element={<GCIOutdoorFreestyleRocker />}></Route>
          <Route path="Camping/CampChairs/Reviews/HelinoxChairOne" element={<HelinoxChairOne />}></Route>
          <Route path="Camping/CampChairs/Reviews/REICoopFlexliteChair" element={<REICoopFlexliteChair />}></Route>

          <Route path="Camping/FireStarters" element={<FireStarters />}></Route>
          <Route path="Camping/FireStarters/Reviews/BICLighter" element={<BICLighter />}></Route>
          <Route path="Camping/FireStarters/Reviews/FireStarterSticksbyCoghlans" element={<FireStarterSticksbyCoghlans />}></Route>
          <Route path="Camping/FireStarters/Reviews/UCOStormproofMatches" element={<UCOStormproofMatches />}></Route>

          <Route path="Camping/SleepingBags" element={<SleepingBags />}></Route>
          <Route path="Camping/SleepingBags/Reviews/ColemanSleepingBagforKids" element={<ColemanSleepingBagforKids />}></Route>
          <Route path="Camping/SleepingBags/Reviews/KeltyCosmic20SleepingBag" element={<KeltyCosmic20SleepingBag />}></Route>
          <Route path="Camping/SleepingBags/Reviews/NorthFaceBlueKazooSleepingBag" element={<NorthFaceBlueKazooSleepingBag />}></Route>
          <Route path="Camping/SleepingBags/Reviews/REICoopMagma15SleepingBag" element={<REICoopMagma15SleepingBag />}></Route>
          <Route path="Camping/SleepingBags/Reviews/TetonSportsCelsiusSleepingBag" element={<TetonSportsCelsiusSleepingBag />}></Route>

          <Route path="Electronics/HandheldGPS" element={<HandheldGPS />}></Route>
          <Route path="Electronics/HandheldGPS/Reviews/GarmineTrex32x" element={<GarmineTrex32x />}></Route>
          <Route path="Electronics/HandheldGPS/Reviews/GarminGPSMAP66i" element={<GarminGPSMAP66i />}></Route>
          <Route path="Electronics/HandheldGPS/Reviews/GarminOregon700" element={<GarminOregon700 />}></Route>
          <Route path="Electronics/HandheldGPS/Reviews/LowranceiFinderHuntC" element={<LowranceiFinderHuntC />}></Route>
          <Route path="Electronics/HandheldGPS/Reviews/MagellaneXplorist310" element={<MagellaneXplorist310 />}></Route>

          <Route path="Electronics/SatelliteCommunicators" element={<SatelliteCommunicators />}></Route>
          <Route path="Electronics/SatelliteCommunicators/Reviews/GarmininReachMini2" element={<GarmininReachMini2 />}></Route>
          <Route path="Electronics/SatelliteCommunicators/Reviews/InReachSESatelliteCommunicator" element={<InReachSESatelliteCommunicator />}></Route>
          <Route path="Electronics/SatelliteCommunicators/Reviews/IridiumExtreme9575" element={<IridiumExtreme9575 />}></Route>
          <Route path="Electronics/SatelliteCommunicators/Reviews/SpotGen4SatelliteMessenger" element={<SpotGen4SatelliteMessenger />}></Route>
          <Route path="Electronics/SatelliteCommunicators/Reviews/ZoleoSatelliteCommunicator" element={<ZoleoSatelliteCommunicator />}></Route>

          <Route path="Electronics/TwoWayRadios" element={<TwoWayRadios />}></Route>
          <Route path="Electronics/TwoWayRadios/Reviews/BaofengBFF8HP" element={<BaofengBFF8HP />}></Route>
          <Route path="Electronics/TwoWayRadios/Reviews/KenwoodTK3402U16P" element={<KenwoodTK3402U16P />}></Route>
          <Route path="Electronics/TwoWayRadios/Reviews/MidlandGXT1000VP4" element={<MidlandGXT1000VP4 />}></Route>
          <Route path="Electronics/TwoWayRadios/Reviews/MotorolaTalkaboutT800" element={<MotorolaTalkaboutT800 />}></Route>
          <Route path="Electronics/TwoWayRadios/Reviews/UnidenGMR50992CKHS" element={<UnidenGMR50992CKHS />}></Route>

          <Route path="Electronics/TrailCams" element={<TrailCams />}></Route>
          <Route path="Electronics/TrailCams/Reviews/BrowningStrikeForceHDPro" element={<BrowningStrikeForceHDPro />}></Route>
          <Route path="Electronics/TrailCams/Reviews/BushnellTrophyCamHD" element={<BushnellTrophyCamHD />}></Route>
          <Route path="Electronics/TrailCams/Reviews/MoultrieA40i" element={<MoultrieA40i />}></Route>
          <Route path="Electronics/TrailCams/Reviews/StealthCamG36NG" element={<StealthCamG36NG />}></Route>
          <Route path="Electronics/TrailCams/Reviews/WildgameInnovationsTerra14" element={<WildgameInnovationsTerra14 />}></Route>

          <Route path="HuntingGear/HuntingKnives" element={<HuntingKnives />}></Route>
          <Route path="HuntingGear/HuntingKnives/Reviews/BenchmadeGrizzlyRidgeFoldingKnife" element={<BenchmadeGrizzlyRidgeFoldingKnife />}></Route>
          <Route path="HuntingGear/HuntingKnives/Reviews/BuckKnives119Special" element={<BuckKnives119Special />}></Route>
          <Route path="HuntingGear/HuntingKnives/Reviews/GerberStrongArmFixedBladeKnife" element={<GerberStrongArmFixedBladeKnife />}></Route>
          <Route path="HuntingGear/HuntingKnives/Reviews/HavalonPirantaZFoldingKnife" element={<HavalonPirantaZFoldingKnife />}></Route>
          <Route path="HuntingGear/HuntingKnives/Reviews/KershawKenOnionBlurFoldingKnife" element={<KershawKenOnionBlurFoldingKnife />}></Route>

          <Route path="HuntingGear/HuntingPacks" element={<HuntingPacks />}></Route>
          <Route path="HuntingGear/HuntingPacks/Reviews/ALPSOutdoorZPursuitPack" element={<ALPSOutdoorZPursuitPack />}></Route>
          <Route path="HuntingGear/HuntingPacks/Reviews/Badlands2200Pack" element={<Badlands2200Pack />}></Route>
          <Route path="HuntingGear/HuntingPacks/Reviews/EberlestockX2Pack" element={<EberlestockX2Pack />}></Route>
          <Route path="HuntingGear/HuntingPacks/Reviews/KuiuUltra3000" element={<KuiuUltra3000 />}></Route>
          <Route path="HuntingGear/HuntingPacks/Reviews/MysteryRanchMetcalf" element={<MysteryRanchMetcalf />}></Route>

          <Route path="Optics/Binoculars" element={<Binoculars />}></Route>
          <Route path="Optics/Binoculars/Reviews/BushnellLegendLSeries" element={<BushnellLegendLSeries />}></Route>
          <Route path="Optics/Binoculars/Reviews/CelestronNatureDX" element={<CelestronNatureDX />}></Route>
          <Route path="Optics/Binoculars/Reviews/LeupoldBX2TiogaHD" element={<LeupoldBX2TiogaHD />}></Route>
          <Route path="Optics/Binoculars/Reviews/NikonMonarch5" element={<NikonMonarch5 />}></Route>
          <Route path="Optics/Binoculars/Reviews/VortexOpticsDiamondbackHD" element={<VortexOpticsDiamondbackHD />}></Route>

          <Route path="Optics/RedDotSights" element={<RedDotSights />}></Route>
          <Route path="Optics/RedDotSights/Reviews/BushnellTRS25RedDotSight" element={<BushnellTRS25RedDotSight />}></Route>
          <Route path="Optics/RedDotSights/Reviews/EOTechEXPS3HolographicSight" element={<EOTechEXPS3HolographicSight />}></Route>
          <Route path="Optics/RedDotSights/Reviews/LeupoldDeltaPointPro" element={<LeupoldDeltaPointPro />}></Route>
          <Route path="Optics/RedDotSights/Reviews/SigSauerRomeo5" element={<SigSauerRomeo5 />}></Route>
          <Route path="Optics/RedDotSights/Reviews/VortexOpticsVenomRedDotSight" element={<VortexOpticsVenomRedDotSight />}></Route>

          <Route path="PrivacyPolicy" element={<PrivacyPolicy />}></Route>

        </Routes>
		</BrowserRouter>

    
  );
}

export default App;