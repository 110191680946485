import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MoultrieA40iPic from '../images/MoultrieA40i.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import TrailCamsPic from '../../../images/TrailCams.jpg';


function MoultrieA40i () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Moultrie A-40i</h1>
          Average Rating (4.3) <Rating name="read-only" value={4.3} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.moultriefeeders.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ TrailCamsPic} style={{height:'120px'}} alt="TrailCams"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Moultrie A-40i" height="130px" src={MoultrieA40iPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users highlight the integrated viewer as a major plus, allowing for easy viewing of captured images in the field without needing to take down the camera. The picture quality has also received commendations, with several users noting sharp and vivid images. However, some have expressed concern about the longevity of the product, with a few units experiencing failures after only a season.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Integrated viewer</li>
<li className="ListItemStyle">Sharp image quality</li>
<li className="ListItemStyle">User-friendly</li>
<li className="ListItemStyle">Good battery efficiency</li>
<li className="ListItemStyle">Affordable</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Durability concerns</li>
<li className="ListItemStyle">Slow trigger speed</li>
<li className="ListItemStyle">Limited night vision range</li>
<li className="ListItemStyle">Bulkier design</li>
<li className="ListItemStyle">Software can be buggy</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Integrated viewer</li>
<li className="ListItemStyle">Sharp image quality</li>
<li className="ListItemStyle">User-friendly</li>
<li className="ListItemStyle">Good battery efficiency</li>
<li className="ListItemStyle">Affordable</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Durability concerns</li>
<li className="ListItemStyle">Slow trigger speed</li>
<li className="ListItemStyle">Limited night vision range</li>
<li className="ListItemStyle">Bulkier design</li>
<li className="ListItemStyle">Software can be buggy</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default MoultrieA40i;

