import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import FireStartersPic from '../images/FireStarters.jpg';

//import BICLighterPic from './images/BICLighter.png';
//import FireStarterSticksbyCoghlansPic from './images/FireStarterSticksbyCoghlans.png';
//import FirestarterCubesbyWSGearPic from './images/FirestarterCubesbyWSGear.png';
//import MagnesiumFireStarterKitPic from './images/MagnesiumFireStarterKit.png';
//import UCOStormproofMatchesPic from './images/UCOStormproofMatches.png';


function FireStarters () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.bic.com/",
      //imageAlt:"BIC Lighter",
      //imageRef:BICLighterPic,
      id:"BICLighter",
      itemLink:"../Camping/FireStarters/Reviews/BICLighter",
      itemBoldText:"BIC Lighter",
      itemText:"The BIC Lighter is a reliable and easy-to-use flame source. Its compact design and dependable ignition make it a favorite among campers, hikers, and backyard grillers alike.",
      averageRating:4.7
    },
	    {
      manuLink:"https://www.coghlans.com/",
      //imageAlt:"Fire Starter Sticks (by Coghlan's)",
      //imageRef:FireStarterSticksbyCoghlansPic,
      id:"FireStarterSticksbyCoghlans",
      itemLink:"../Camping/FireStarters/Reviews/FireStarterSticksbyCoghlans",
      itemBoldText:"Fire Starter Sticks (by Coghlan's)",
      itemText:"Coghlan's Fire Starter Sticks are designed for quick and easy fire starting, even in wet conditions. Each stick burns for up to 10 minutes, making it easier to ignite wood and other materials.",
      averageRating:4.5
    },

	    {
      manuLink:"https://ucogear.com/",
      //imageAlt:"UCO Stormproof Matches",
      //imageRef:UCOStormproofMatchesPic,
      id:"UCOStormproofMatches",
      itemLink:"../Camping/FireStarters/Reviews/UCOStormproofMatches",
      itemBoldText:"UCO Stormproof Matches",
      itemText:"UCO Stormproof Matches are designed for extreme conditions, allowing you to light a fire in the wind and rain. They burn for approximately 15 seconds, making them a reliable choice for outdoors enthusiasts.",
      averageRating:4.8
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top Fire Starters for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={FireStartersPic} style={{height:'120px'}} alt="Fire Starters"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Being able to start a fire when in the outdoors can rarely be a life-threatening situation. However, when in that situation it is critical that you be able to start a fire. Other times it can mean the difference between a great time around a campfire and a not-so-great time sitting out in the cold. Below are some great options for starting a fire when outdoors.

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default FireStarters;

