import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import HelinoxChairOnePic from '../images/HelinoxChairOne.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import CampChairsPic from '../../../images/CampChairs.jpg';


function HelinoxChairOne () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Helinox Chair One</h1>
          Average Rating (4.6) <Rating name="read-only" value={4.6} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://helinox.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampChairsPic} style={{height:'120px'}} alt="CampChairs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Helinox Chair One" height="130px" src={HelinoxChairOnePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users rave about the Helinox Chair One's compactness and lightweight design, making it easy to carry on hikes. Many highlight its comfortable seating and robust build, which withstands various outdoor conditions. Some reviewers mention the higher price point but acknowledge that the quality justifies the investment.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Extremely lightweight and compact</li>
<li className="ListItemStyle">Easy to assemble and disassemble</li>
<li className="ListItemStyle">Durable and sturdy construction</li>
<li className="ListItemStyle">Comfortable for extended sitting</li>
<li className="ListItemStyle">Stylish design</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price compared to other camp chairs</li>
<li className="ListItemStyle">May be less comfortable for larger individuals</li>
<li className="ListItemStyle">Limited padding</li>
<li className="ListItemStyle">Not ideal for rough terrains</li>
<li className="ListItemStyle">Requires careful assembly to avoid damage</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Extremely lightweight and compact</li>
<li className="ListItemStyle">Easy to assemble and disassemble</li>
<li className="ListItemStyle">Durable and sturdy construction</li>
<li className="ListItemStyle">Comfortable for extended sitting</li>
<li className="ListItemStyle">Stylish design</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price compared to other camp chairs</li>
<li className="ListItemStyle">May be less comfortable for larger individuals</li>
<li className="ListItemStyle">Limited padding</li>
<li className="ListItemStyle">Not ideal for rough terrains</li>
<li className="ListItemStyle">Requires careful assembly to avoid damage</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default HelinoxChairOne;

