import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import LeupoldDeltaPointProPic from '../images/LeupoldDeltaPointPro.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import RedDotSightsPic from '../../../images/RedDotSights.jpg';


function LeupoldDeltaPointPro () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Leupold DeltaPoint Pro</h1>
          Average Rating (4.6) <Rating name="read-only" value={4.6} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.leupold.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ RedDotSightsPic} style={{height:'120px'}} alt="RedDotSights"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Leupold DeltaPoint Pro" height="130px" src={LeupoldDeltaPointProPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users rave about the DeltaPoint Pro's quick target acquisition and the versatility it offers for use on various firearms. The high-quality glass and impressive clarity are frequently highlighted, alongside the amazing battery performance which can last for months. Additionally, many appreciate the unique mounting system that allows it to fit on different platforms.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-quality optics</li>
<li className="ListItemStyle">Motion sensor for instant activation</li>
<li className="ListItemStyle">Lightweight and easy to mount</li>
<li className="ListItemStyle">Exceptional low-light performance</li>
<li className="ListItemStyle">Durable and weather-resistant</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Price can be prohibitive for some</li>
<li className="ListItemStyle">Battery life can vary based on usage</li>
<li className="ListItemStyle">Adjustment knobs may feel stiff</li>
<li className="ListItemStyle">Not ideal for very small handguns</li>
<li className="ListItemStyle">Limited windage and elevation range</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-quality optics</li>
<li className="ListItemStyle">Motion sensor for instant activation</li>
<li className="ListItemStyle">Lightweight and easy to mount</li>
<li className="ListItemStyle">Exceptional low-light performance</li>
<li className="ListItemStyle">Durable and weather-resistant</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Price can be prohibitive for some</li>
<li className="ListItemStyle">Battery life can vary based on usage</li>
<li className="ListItemStyle">Adjustment knobs may feel stiff</li>
<li className="ListItemStyle">Not ideal for very small handguns</li>
<li className="ListItemStyle">Limited windage and elevation range</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default LeupoldDeltaPointPro;

