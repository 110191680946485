import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import NorthFaceBlueKazooSleepingBagPic from '../images/NorthFaceBlueKazooSleepingBag.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import SleepingBagsPic from '../../../images/SleepingBags.jpg';


function NorthFaceBlueKazooSleepingBag () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for North Face Blue Kazoo Sleeping Bag</h1>
          Average Rating (4.7) <Rating name="read-only" value={4.7} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.thenorthface.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ SleepingBagsPic} style={{height:'120px'}} alt="SleepingBags"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="North Face Blue Kazoo Sleeping Bag" height="130px" src={NorthFaceBlueKazooSleepingBagPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Many users praise the North Face Blue Kazoo for its impressive warmth, even in extremely cold conditions, making it ideal for winter camping. Others appreciate its lightweight design, which allows for easy packing during long treks. A few users noted the bag's durability, even after extensive use, solidifying its value as an investment for serious outdoor enthusiasts.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Excellent warmth-to-weight ratio</li>
<li className="ListItemStyle">Compact and lightweight for easy transport</li>
<li className="ListItemStyle">Durable materials</li>
<li className="ListItemStyle">Comfortable design with draft protection</li>
<li className="ListItemStyle">Good for various weather conditions</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point</li>
<li className="ListItemStyle">Could be too narrow for some users</li>
<li className="ListItemStyle">Zipper can snag on fabric</li>
<li className="ListItemStyle">Limited color options</li>
<li className="ListItemStyle">Requires additional maintenance for down insulation</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Excellent warmth-to-weight ratio</li>
<li className="ListItemStyle">Compact and lightweight for easy transport</li>
<li className="ListItemStyle">Durable materials</li>
<li className="ListItemStyle">Comfortable design with draft protection</li>
<li className="ListItemStyle">Good for various weather conditions</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point</li>
<li className="ListItemStyle">Could be too narrow for some users</li>
<li className="ListItemStyle">Zipper can snag on fabric</li>
<li className="ListItemStyle">Limited color options</li>
<li className="ListItemStyle">Requires additional maintenance for down insulation</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default NorthFaceBlueKazooSleepingBag;

