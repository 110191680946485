import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import HuntingPacksPic from '../images/HuntingPacks.jpg';

//import ALPSOutdoorZPursuitPackPic from './images/ALPSOutdoorZPursuitPack.png';
//import Badlands2200PackPic from './images/Badlands2200Pack.png';
//import EberlestockX2PackPic from './images/EberlestockX2Pack.png';
//import KuiuUltra3000Pic from './images/KuiuUltra3000.png';
//import MysteryRanchMetcalfPic from './images/MysteryRanchMetcalf.png';


function HuntingPacks () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.alpsoutdoorz.com/",
      //imageAlt:"ALPS OutdoorZ Pursuit Pack",
      //imageRef:ALPSOutdoorZPursuitPackPic,
      id:"ALPSOutdoorZPursuitPack",
      itemLink:"../HuntingGear/HuntingPacks/Reviews/ALPSOutdoorZPursuitPack",
      itemBoldText:"ALPS OutdoorZ Pursuit Pack",
      itemText:"The ALPS OutdoorZ Pursuit Pack is an affordable entry option for those new to hunting. This pack combines functionality with value, offering enough fittings and pockets for a day in the field without breaking the bank.",
      averageRating:4.3
    },
	    {
      manuLink:"https://badlandspacks.com",
      //imageAlt:"Badlands 2200 Pack",
      //imageRef:Badlands2200PackPic,
      id:"Badlands2200Pack",
      itemLink:"../HuntingGear/HuntingPacks/Reviews/Badlands2200Pack",
      itemBoldText:"Badlands 2200 Pack",
      itemText:"The Badlands 2200 Pack is a popular choice among hunters who need a reliable and functional pack for day trips or extended excursions. It features an innovative design that allows for quick access to gear and an excellent hydration system.",
      averageRating:4.5
    },
	    {
      manuLink:"https://eberlestock.com",
      //imageAlt:"Eberlestock X2 Pack",
      //imageRef:EberlestockX2PackPic,
      id:"EberlestockX2Pack",
      itemLink:"../HuntingGear/HuntingPacks/Reviews/EberlestockX2Pack",
      itemBoldText:"Eberlestock X2 Pack",
      itemText:"The Eberlestock X2 Pack is designed specifically for the mobile hunter who requires versatility and stability. With its unique design for bow and rifle carry, this pack is perfect for those who need to traverse various terrains.",
      averageRating:4.4
    },
	    {
      manuLink:"https://www.kuiu.com/",
      //imageAlt:"Kuiu Ultra 3000",
      //imageRef:KuiuUltra3000Pic,
      id:"KuiuUltra3000",
      itemLink:"../HuntingGear/HuntingPacks/Reviews/KuiuUltra3000",
      itemBoldText:"Kuiu Ultra 3000",
      itemText:"The Kuiu Ultra 3000 is built for serious hunters who demand lightweight performance without sacrificing capacity. This pack combines a high-tech design with durable materials and a sleek look, providing hunters with ease of movement and organization.",
      averageRating:4.6
    },
	    {
      manuLink:"https://www.mysteryranch.com",
      //imageAlt:"Mystery Ranch Metcalf",
      //imageRef:MysteryRanchMetcalfPic,
      id:"MysteryRanchMetcalf",
      itemLink:"../HuntingGear/HuntingPacks/Reviews/MysteryRanchMetcalf",
      itemBoldText:"Mystery Ranch Metcalf",
      itemText:"The Mystery Ranch Metcalf is a versatile hunting pack designed for long treks in the backcountry. With its functional design, it offers ample storage space, adjustable features for comfort, and a durable build that can withstand rugged environments.",
      averageRating:4.7
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top Hunting Packs for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={HuntingPacksPic} style={{height:'120px'}} alt="Hunting Packs"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Having a comfortable hunting pack that has the necessary storage to carry everything you need can make a hunt much more enjoyable. Whether you are on a day hunt or plan to be out several days, your pack is an important thing to have. Below are some fantastic options for your next hunt.

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default HuntingPacks;

