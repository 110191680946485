import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GCIOutdoorFreestyleRockerPic from '../images/GCIOutdoorFreestyleRocker.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import CampChairsPic from '../../../images/CampChairs.jpg';


function GCIOutdoorFreestyleRocker () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for GCI Outdoor Freestyle Rocker</h1>
          Average Rating (4.4) <Rating name="read-only" value={4.4} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://gcioutdoor.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampChairsPic} style={{height:'120px'}} alt="CampChairs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="GCI Outdoor Freestyle Rocker" height="130px" src={GCIOutdoorFreestyleRockerPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Many users enjoy the rocking feature of the GCI Outdoor Freestyle Rocker, making it a relaxing addition to their outdoor experience. Reviewers mention its sturdy build and ease of folding for storage. Some users do express concern over its weight, finding it less feasible for hiking but perfect for leisure activities.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Unique rocking feature</li>
<li className="ListItemStyle">Sturdy construction</li>
<li className="ListItemStyle">Portable design</li>
<li className="ListItemStyle">Comfortable seating experience</li>
<li className="ListItemStyle">Easy to fold and store</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier than standard camp chairs</li>
<li className="ListItemStyle">More expensive than basic models</li>
<li className="ListItemStyle">Rocking motion may not suit everyone</li>
<li className="ListItemStyle">Limited adjustability</li>
<li className="ListItemStyle">May not fit in smaller vehicle spaces</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Unique rocking feature</li>
<li className="ListItemStyle">Sturdy construction</li>
<li className="ListItemStyle">Portable design</li>
<li className="ListItemStyle">Comfortable seating experience</li>
<li className="ListItemStyle">Easy to fold and store</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier than standard camp chairs</li>
<li className="ListItemStyle">More expensive than basic models</li>
<li className="ListItemStyle">Rocking motion may not suit everyone</li>
<li className="ListItemStyle">Limited adjustability</li>
<li className="ListItemStyle">May not fit in smaller vehicle spaces</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default GCIOutdoorFreestyleRocker;

