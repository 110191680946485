import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import TwoWayRadiosPic from '../images/TwoWayRadios.jpg';

//import BaofengBFF8HPPic from './images/BaofengBFF8HP.png';
//import KenwoodTK3402U16PPic from './images/KenwoodTK3402U16P.png';
//import MidlandGXT1000VP4Pic from './images/MidlandGXT1000VP4.png';
//import MotorolaTalkaboutT800Pic from './images/MotorolaTalkaboutT800.png';
//import UnidenGMR50992CKHSPic from './images/UnidenGMR50992CKHS.png';


function TwoWayRadios () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.baofengtech.com/",
      //imageAlt:"Baofeng BF-F8HP",
      //imageRef:BaofengBFF8HPPic,
      id:"BaofengBFF8HP",
      itemLink:"../Electronics/TwoWayRadios/Reviews/BaofengBFF8HP",
      itemBoldText:"Baofeng BF-F8HP",
      itemText:"The Baofeng BF-F8HP is a powerful handheld radio that combines commercial-grade features with ease of use. It provides a range of up to 8 miles and features a high power output for strong clarity. Though slightly more technical than other models, it is favored by ham radio enthusiasts for its flexibility.",
      averageRating:4.6
    },
	    {
      manuLink:"https://www.kenwood.com/usa/",
      //imageAlt:"Kenwood TK-3402U16P",
      //imageRef:KenwoodTK3402U16PPic,
      id:"KenwoodTK3402U16P",
      itemLink:"../Electronics/TwoWayRadios/Reviews/KenwoodTK3402U16P",
      itemBoldText:"Kenwood TK-3402U16P",
      itemText:"The Kenwood TK-3402U16P professional-grade 2-way radio is designed for durability and effective communication in demanding environments. With UHF frequencies for superior range and audio clarity, this radio is often used in construction and event management. It features a rugged design to withstand harsh conditions.",
      averageRating:4.4
    },
	    {
      manuLink:"https://midlandradio.com",
      //imageAlt:"Midland GXT1000VP4",
      //imageRef:MidlandGXT1000VP4Pic,
      id:"MidlandGXT1000VP4",
      itemLink:"../Electronics/TwoWayRadios/Reviews/MidlandGXT1000VP4",
      itemBoldText:"Midland GXT1000VP4",
      itemText:"The Midland GXT1000VP4 2-way radios are packed with features and boast a 36-mile range. They come equipped with 50 channels, NOAA weather alerts, and built-in privacy codes to reduce interference. The compact design and robust build make them a staple for outdoor enthusiasts and families.",
      averageRating:4.7
    },
	    {
      manuLink:"https://www.motorolasolutions.com",
      //imageAlt:"Motorola Talkabout T800",
      //imageRef:MotorolaTalkaboutT800Pic,
      id:"MotorolaTalkaboutT800",
      itemLink:"../Electronics/TwoWayRadios/Reviews/MotorolaTalkaboutT800",
      itemBoldText:"Motorola Talkabout T800",
      itemText:"The Motorola Talkabout T800 is a versatile and reliable 2-way radio with a robust design. Ideal for outdoor adventures, it features a range of up to 35 miles, Bluetooth connectivity for messaging without cell service, and built-in NOAA weather alerts. Its user-friendly interface and durable construction make it a favorite among campers and hikers alike.",
      averageRating:4.5
    },
	    {
      manuLink:"https://www.uniden.com",
      //imageAlt:"Uniden GMR5099-2CKHS",
      //imageRef:UnidenGMR50992CKHSPic,
      id:"UnidenGMR50992CKHS",
      itemLink:"../Electronics/TwoWayRadios/Reviews/UnidenGMR50992CKHS",
      itemBoldText:"Uniden GMR5099-2CKHS",
      itemText:"The Uniden GMR5099-2CKHS offers great features for outdoor use, including a range of up to 50 miles and 22 channels. With a waterproof design and rugged construction, it's made to withstand tough conditions. This model is known for its clear sound quality and long battery life, making it suitable for extended outings.",
      averageRating:4.3
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top 2-Way Radios for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={TwoWayRadiosPic} style={{height:'120px'}} alt="2-Way Radios"></img>
        </td>
      </tr>
      </tbody>
    </table>


    2-way radios are a great tool to stay connected with your buddies when you are out having fun in the outdoors. Whether you are letting them know where you are, or if you need help with something, it is great to press a button to talk instead of needing to track them down. Below are some great options for you to consider for your next 2-way radio.

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default TwoWayRadios;

