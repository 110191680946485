import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import BinocularsPic from '../images/Binoculars.jpg';

//import BushnellLegendLSeriesPic from './images/BushnellLegendLSeries.png';
//import CelestronNatureDXPic from './images/CelestronNatureDX.png';
//import LeupoldBX2TiogaHDPic from './images/LeupoldBX2TiogaHD.png';
//import NikonMonarch5Pic from './images/NikonMonarch5.png';
//import VortexOpticsDiamondbackHDPic from './images/VortexOpticsDiamondbackHD.png';


function Binoculars () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.bushnell.com",
      //imageAlt:"Bushnell Legend L-Series",
      //imageRef:BushnellLegendLSeriesPic,
      id:"BushnellLegendLSeries",
      itemLink:"../Optics/Binoculars/Reviews/BushnellLegendLSeries",
      itemBoldText:"Bushnell Legend L-Series",
      itemText:"The Bushnell Legend L-Series binoculars are known for their high-quality optics and rugged design, making them perfect for outdoor enthusiasts. They feature RainGuard HD water-repellent coating to enhance visibility in wet conditions and deliver exceptional image brightness.",
      averageRating:4.5
    },
	    {
      manuLink:"https://www.celestron.com",
      //imageAlt:"Celestron Nature DX",
      //imageRef:CelestronNatureDXPic,
      id:"CelestronNatureDX",
      itemLink:"../Optics/Binoculars/Reviews/CelestronNatureDX",
      itemBoldText:"Celestron Nature DX",
      itemText:"Celestron Nature DX binoculars are great for nature lovers and outdoor enthusiasts seeking quality optics at an affordable price. With a durable, waterproof design and fully multi-coated lenses, they feature impressive light transmission and clarity.",
      averageRating:4.4
    },
	    {
      manuLink:"https://www.leupold.com",
      //imageAlt:"Leupold BX-2 Tioga HD",
      //imageRef:LeupoldBX2TiogaHDPic,
      id:"LeupoldBX2TiogaHD",
      itemLink:"../Optics/Binoculars/Reviews/LeupoldBX2TiogaHD",
      itemBoldText:"Leupold BX-2 Tioga HD",
      itemText:"Leupold BX-2 Tioga HD binoculars are high-performance optical instruments designed for avid outdoorsmen. These binoculars feature advanced optics for superior brightness, edge-to-edge clarity, and a rugged design that ensures reliability in any environment.",
      averageRating:4.7
    },
	    {
      manuLink:"https://www.nikonusa.com",
      //imageAlt:"Nikon Monarch 5",
      //imageRef:NikonMonarch5Pic,
      id:"NikonMonarch5",
      itemLink:"../Optics/Binoculars/Reviews/NikonMonarch5",
      itemBoldText:"Nikon Monarch 5",
      itemText:"Nikon Monarch 5 binoculars feature advanced optical technology in a compact design, making them ideal for wildlife observation and outdoor activities. These binoculars offer a wide field of view and high-resolution images thanks to their superior multicoated optics.",
      averageRating:4.6
    },
	    {
      manuLink:"https://vortexoptics.com",
      //imageAlt:"Vortex Optics Diamondback HD",
      //imageRef:VortexOpticsDiamondbackHDPic,
      id:"VortexOpticsDiamondbackHD",
      itemLink:"../Optics/Binoculars/Reviews/VortexOpticsDiamondbackHD",
      itemBoldText:"Vortex Optics Diamondback HD",
      itemText:"The Vortex Optics Diamondback HD binoculars offer high-definition clarity and are designed for outdoor enthusiasts. Featuring a rugged, lightweight build and impressive optical performance with fully multi-coated lenses, they provide excellent light transmission and sharp images.",
      averageRating:4.8
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top Binoculars for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={BinocularsPic} style={{height:'120px'}} alt="Binoculars"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Having a pair of binoculars opens up a world of possibilities, bringing distant sights closer and enhancing outdoor experiences. Whether you're watching birds, exploring landscapes, or attending sports events, binoculars allow you to see details that would otherwise go unnoticed. They provide a greater appreciation of nature, enabling you to observe wildlife from a respectful distance without disturbing their natural habitat.

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default Binoculars;

