import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MotorolaTalkaboutT800Pic from '../images/MotorolaTalkaboutT800.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import TwoWayRadiosPic from '../../../images/TwoWayRadios.jpg';


function MotorolaTalkaboutT800 () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Motorola Talkabout T800</h1>
          Average Rating (4.5) <Rating name="read-only" value={4.5} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.motorolasolutions.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ TwoWayRadiosPic} style={{height:'120px'}} alt="2WayRadios"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Motorola Talkabout T800" height="130px" src={MotorolaTalkaboutT800Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Many users love the extensive range of the Motorola Talkabout T800, making it perfect for group outings in remote areas. The Bluetooth functionality is another highlight, allowing seamless messaging and sharing without relying on cellular networks. However, some reviews mention that while the sound quality is generally good, it can suffer in windy conditions, making it crucial to find sheltered spots during conversations.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Long range up to 35 miles</li>
<li className="ListItemStyle">Bluetooth messaging capability</li>
<li className="ListItemStyle">NOAA weather alerts</li>
<li className="ListItemStyle">Durable design</li>
<li className="ListItemStyle">User-friendly interface</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Sound quality affected by wind</li>
<li className="ListItemStyle">Batteries can drain quickly</li>
<li className="ListItemStyle">Bulky compared to some competitors</li>
<li className="ListItemStyle">Setup can be confusing initially</li>
<li className="ListItemStyle">Pricey for casual use</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Long range up to 35 miles</li>
<li className="ListItemStyle">Bluetooth messaging capability</li>
<li className="ListItemStyle">NOAA weather alerts</li>
<li className="ListItemStyle">Durable design</li>
<li className="ListItemStyle">User-friendly interface</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Sound quality affected by wind</li>
<li className="ListItemStyle">Batteries can drain quickly</li>
<li className="ListItemStyle">Bulky compared to some competitors</li>
<li className="ListItemStyle">Setup can be confusing initially</li>
<li className="ListItemStyle">Pricey for casual use</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default MotorolaTalkaboutT800;

