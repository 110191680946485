import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import KenwoodTK3402U16PPic from '../images/KenwoodTK3402U16P.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import TwoWayRadiosPic from '../../../images/TwoWayRadios.jpg';


function KenwoodTK3402U16P () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Kenwood TK-3402U16P</h1>
          Average Rating (4.4) <Rating name="read-only" value={4.4} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.kenwood.com/usa/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ TwoWayRadiosPic} style={{height:'120px'}} alt="2WayRadios"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Kenwood TK-3402U16P" height="130px" src={KenwoodTK3402U16PPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users working in industrial or construction settings highly recommend the Kenwood TK-3402U16P for its durability and range. They appreciate the crisp audio quality, even in noisy environments. A few users mentioned that it's on the pricier side for casual use but well worth it for professional settings.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Durable and rugged design</li>
<li className="ListItemStyle">Crisp audio quality</li>
<li className="ListItemStyle">UHF frequencies for extended range</li>
<li className="ListItemStyle">Ideal for professional use</li>
<li className="ListItemStyle">Long-lasting battery</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point</li>
<li className="ListItemStyle">Not intended for casual users</li>
<li className="ListItemStyle">Limited channels</li>
<li className="ListItemStyle">May require licensing</li>
<li className="ListItemStyle">Bulky for casual carrying</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Durable and rugged design</li>
<li className="ListItemStyle">Crisp audio quality</li>
<li className="ListItemStyle">UHF frequencies for extended range</li>
<li className="ListItemStyle">Ideal for professional use</li>
<li className="ListItemStyle">Long-lasting battery</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point</li>
<li className="ListItemStyle">Not intended for casual users</li>
<li className="ListItemStyle">Limited channels</li>
<li className="ListItemStyle">May require licensing</li>
<li className="ListItemStyle">Bulky for casual carrying</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default KenwoodTK3402U16P;

