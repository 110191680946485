import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import CampChefEverestCampStovePic from '../images/CampChefEverestCampStove.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import CampStovesPic from '../../../images/CampStoves.jpg';


function CampChefEverestCampStove () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Camp Chef Everest Camp Stove</h1>
          Average Rating (4.9) <Rating name="read-only" value={4.9} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.campchef.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampStovesPic} style={{height:'120px'}} alt="CampStoves"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Camp Chef Everest Camp Stove" height="130px" src={CampChefEverestCampStovePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users are extremely satisfied with the Camp Chef Everest's rapid heating capabilities, making meal preparation quick and efficient. Many also highlight its rugged durability and portability, finding it perfect for various outdoor adventures. A few customers mentioned needing to get used to adjusting the flames accurately, but overall, it's deemed a top choice for camping.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Incredibly fast boil time</li>
<li className="ListItemStyle">Highly durable and sturdy construction</li>
<li className="ListItemStyle">Precise heat controls for cooking</li>
<li className="ListItemStyle">Portability with a compact design</li>
<li className="ListItemStyle">Impressive performance in windy conditions</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price than standard models</li>
<li className="ListItemStyle">Requires specific fuel types</li>
<li className="ListItemStyle">Not as lightweight as backpacking options</li>
<li className="ListItemStyle">Setup can be more complex for some users</li>
<li className="ListItemStyle">Some complaints about the fuel consumption rate</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Incredibly fast boil time</li>
<li className="ListItemStyle">Highly durable and sturdy construction</li>
<li className="ListItemStyle">Precise heat controls for cooking</li>
<li className="ListItemStyle">Portability with a compact design</li>
<li className="ListItemStyle">Impressive performance in windy conditions</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price than standard models</li>
<li className="ListItemStyle">Requires specific fuel types</li>
<li className="ListItemStyle">Not as lightweight as backpacking options</li>
<li className="ListItemStyle">Setup can be more complex for some users</li>
<li className="ListItemStyle">Some complaints about the fuel consumption rate</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default CampChefEverestCampStove;

