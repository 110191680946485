import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import IridiumExtreme9575Pic from '../images/IridiumExtreme9575.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import SatelliteCommunicatorsPic from '../../../images/SatelliteCommunicators.jpg';


function IridiumExtreme9575 () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Iridium Extreme 9575</h1>
          Average Rating (4.5) <Rating name="read-only" value={4.5} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.iridium.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ SatelliteCommunicatorsPic} style={{height:'120px'}} alt="SatelliteCommunicators"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Iridium Extreme 9575" height="130px" src={IridiumExtreme9575Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Customers appreciate the voice call option, which adds an extra layer of communication that's missing in many satellite communicators. The robust build and high durability make it suitable for use in harsh environments, yet some users wish for a somewhat simplified interface for messaging. Battery drain is a common concern with heavy use, so managing usage is key.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Voice calling capability</li>
<li className="ListItemStyle">Durable for extreme conditions</li>
<li className="ListItemStyle">Global coverage</li>
<li className="ListItemStyle">Multiple user modes</li>
<li className="ListItemStyle">Integrated SOS button</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Bulky and heavy</li>
<li className="ListItemStyle">Higher price point</li>
<li className="ListItemStyle">Complicated interface for new users</li>
<li className="ListItemStyle">Battery life issues under heavy use</li>
<li className="ListItemStyle">Signal can be weak in heavy storms</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Voice calling capability</li>
<li className="ListItemStyle">Durable for extreme conditions</li>
<li className="ListItemStyle">Global coverage</li>
<li className="ListItemStyle">Multiple user modes</li>
<li className="ListItemStyle">Integrated SOS button</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Bulky and heavy</li>
<li className="ListItemStyle">Higher price point</li>
<li className="ListItemStyle">Complicated interface for new users</li>
<li className="ListItemStyle">Battery life issues under heavy use</li>
<li className="ListItemStyle">Signal can be weak in heavy storms</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default IridiumExtreme9575;

