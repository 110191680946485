import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import PrimusClassicTrailStovePic from '../images/PrimusClassicTrailStove.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import CampStovesPic from '../../../images/CampStoves.jpg';


function PrimusClassicTrailStove () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Primus Classic Trail Stove</h1>
          Average Rating (4.4) <Rating name="read-only" value={4.4} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.primus.us/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampStovesPic} style={{height:'120px'}} alt="CampStoves"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Primus Classic Trail Stove" height="130px" src={PrimusClassicTrailStovePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Reviewers appreciate the Primus Classic Trail Stove for its compact design, making it ideal for backpacking and minimalists. Users often mention the stove's effective cooking performance and ease of use, particularly highlighting its adjustable flame features. However, some have found the fuel consumption to be slightly higher than expected for longer trips.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Compact and lightweight for backpacking</li>
<li className="ListItemStyle">Effective cooking performance</li>
<li className="ListItemStyle">Adjustable flame settings for versatility</li>
<li className="ListItemStyle">Durable build quality</li>
<li className="ListItemStyle">Easy to clean and maintain</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited cooking space for larger meals</li>
<li className="ListItemStyle">Higher fuel consumption than anticipated</li>
<li className="ListItemStyle">Requires special fuel canisters</li>
<li className="ListItemStyle">Initial setup might take some time</li>
<li className="ListItemStyle">Flame control can be less precise at lower settings</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Compact and lightweight for backpacking</li>
<li className="ListItemStyle">Effective cooking performance</li>
<li className="ListItemStyle">Adjustable flame settings for versatility</li>
<li className="ListItemStyle">Durable build quality</li>
<li className="ListItemStyle">Easy to clean and maintain</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited cooking space for larger meals</li>
<li className="ListItemStyle">Higher fuel consumption than anticipated</li>
<li className="ListItemStyle">Requires special fuel canisters</li>
<li className="ListItemStyle">Initial setup might take some time</li>
<li className="ListItemStyle">Flame control can be less precise at lower settings</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default PrimusClassicTrailStove;

