import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import InReachSESatelliteCommunicatorPic from '../images/InReachSESatelliteCommunicator.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import SatelliteCommunicatorsPic from '../../../images/SatelliteCommunicators.jpg';


function InReachSESatelliteCommunicator () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for InReach SE+ Satellite Communicator</h1>
          Average Rating (4.7) <Rating name="read-only" value={4.7} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.garmin.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ SatelliteCommunicatorsPic} style={{height:'120px'}} alt="SatelliteCommunicators"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="InReach SE+ Satellite Communicator" height="130px" src={InReachSESatelliteCommunicatorPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Many reviews highlight the efficient SOS feature as a crucial safety element during remote adventures. Users also enjoy the versatility of two-way messaging, feeling more secure while exploring off-grid locations. A few frustrations arose concerning battery life when used extensively but overall, it's praised for its ruggedness and reliability.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Two-way communication</li>
<li className="ListItemStyle">24/7 SOS response</li>
<li className="ListItemStyle">Durable and rugged design</li>
<li className="ListItemStyle">Interactive mapping features</li>
<li className="ListItemStyle">Long-lasting battery</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">High subscription fees</li>
<li className="ListItemStyle">Heavier than other models</li>
<li className="ListItemStyle">Requires a bit of learning</li>
<li className="ListItemStyle">Limited text length</li>
<li className="ListItemStyle">Occasional issues with connectivity</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Two-way communication</li>
<li className="ListItemStyle">24/7 SOS response</li>
<li className="ListItemStyle">Durable and rugged design</li>
<li className="ListItemStyle">Interactive mapping features</li>
<li className="ListItemStyle">Long-lasting battery</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">High subscription fees</li>
<li className="ListItemStyle">Heavier than other models</li>
<li className="ListItemStyle">Requires a bit of learning</li>
<li className="ListItemStyle">Limited text length</li>
<li className="ListItemStyle">Occasional issues with connectivity</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default InReachSESatelliteCommunicator;

