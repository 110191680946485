import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import EberlestockX2PackPic from '../images/EberlestockX2Pack.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import HuntingPacksPic from '../../../images/HuntingPacks.jpg';


function EberlestockX2Pack () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Eberlestock X2 Pack</h1>
          Average Rating (4.4) <Rating name="read-only" value={4.4} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://eberlestock.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingPacksPic} style={{height:'120px'}} alt="HuntingPacks"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Eberlestock X2 Pack" height="130px" src={EberlestockX2PackPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Users find the X2 to be extremely versatile, praising its carrying options for both bows and rifles. Many have noted how stable and secure the pack feels, even while moving through challenging landscapes. On the downside, a few users mentioned that the materials may feel a bit heavier compared to other light options.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Versatile carrying options for weapons</li>
<li className="ListItemStyle">Stable and secure fit during movement</li>
<li className="ListItemStyle">Ample storage space with organized compartments</li>
<li className="ListItemStyle">Durable and tough materials</li>
<li className="ListItemStyle">Compact design for easy maneuvering</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier than competitive options</li>
<li className="ListItemStyle">Can feel bulky for smaller hunters</li>
<li className="ListItemStyle">Learning curve for proper weight distribution</li>
<li className="ListItemStyle">Zippers may snag on certain materials</li>
<li className="ListItemStyle">Less padding on back panel compared to others</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Versatile carrying options for weapons</li>
<li className="ListItemStyle">Stable and secure fit during movement</li>
<li className="ListItemStyle">Ample storage space with organized compartments</li>
<li className="ListItemStyle">Durable and tough materials</li>
<li className="ListItemStyle">Compact design for easy maneuvering</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier than competitive options</li>
<li className="ListItemStyle">Can feel bulky for smaller hunters</li>
<li className="ListItemStyle">Learning curve for proper weight distribution</li>
<li className="ListItemStyle">Zippers may snag on certain materials</li>
<li className="ListItemStyle">Less padding on back panel compared to others</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default EberlestockX2Pack;

