import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import RedDotSightsPic from '../images/RedDotSights.jpg';

//import BushnellTRS25RedDotSightPic from './images/BushnellTRS25RedDotSight.png';
//import EOTechEXPS3HolographicSightPic from './images/EOTechEXPS3HolographicSight.png';
//import LeupoldDeltaPointProPic from './images/LeupoldDeltaPointPro.png';
//import SigSauerRomeo5Pic from './images/SigSauerRomeo5.png';
//import VortexOpticsVenomRedDotSightPic from './images/VortexOpticsVenomRedDotSight.png';


function RedDotSights () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.bushnell.com/",
      //imageAlt:"Bushnell TRS-25 Red Dot Sight",
      //imageRef:BushnellTRS25RedDotSightPic,
      id:"BushnellTRS25RedDotSight",
      itemLink:"../Optics/RedDotSights/Reviews/BushnellTRS25RedDotSight",
      itemBoldText:"Bushnell TRS-25 Red Dot Sight",
      itemText:"The Bushnell TRS-25 is a budget-friendly red dot sight that doesn't compromise on quality. It's compact and lightweight with a simple interface for easy adjustments. This sight is designed for rapid target acquisition and is popular for recreational shooting and home defense.",
      averageRating:4.4
    },
	    {
      manuLink:"https://www.eotechinc.com/",
      //imageAlt:"EOTech EXPS3 Holographic Sight",
      //imageRef:EOTechEXPS3HolographicSightPic,
      id:"EOTechEXPS3HolographicSight",
      itemLink:"../Optics/RedDotSights/Reviews/EOTechEXPS3HolographicSight",
      itemBoldText:"EOTech EXPS3 Holographic Sight",
      itemText:"The EOTech EXPS3 is an advanced holographic sight that offers quick target acquisition with an unparalleled sight picture. It's ideal for tactical environments and comes equipped with night vision compatibility. This high-performance sight caters to serious shooters and outdoor adventurers.",
      averageRating:4.8
    },
	    {
      manuLink:"https://www.leupold.com",
      //imageAlt:"Leupold DeltaPoint Pro",
      //imageRef:LeupoldDeltaPointProPic,
      id:"LeupoldDeltaPointPro",
      itemLink:"../Optics/RedDotSights/Reviews/LeupoldDeltaPointPro",
      itemBoldText:"Leupold DeltaPoint Pro",
      itemText:"The Leupold DeltaPoint Pro is a robust red dot sight known for its advanced optics and lightweight design. It comes with a motion sensor that activates the sight when it detects movement, ensuring immediate readiness. This sight excels in both low-light and bright environments.",
      averageRating:4.6
    },
	    {
      manuLink:"https://www.sigsauer.com",
      //imageAlt:"Sig Sauer Romeo 5",
      //imageRef:SigSauerRomeo5Pic,
      id:"SigSauerRomeo5",
      itemLink:"../Optics/RedDotSights/Reviews/SigSauerRomeo5",
      itemBoldText:"Sig Sauer Romeo 5",
      itemText:"The Sig Sauer Romeo 5 features a compact design and is known for its impressive performance at an affordable price point. This sight includes a variety of reticle options and brings added versatility for users. Its rugged construction makes it suitable for nearly any environment.",
      averageRating:4.5
    },
	    {
      manuLink:"https://vortexoptics.com/",
      //imageAlt:"Vortex Optics Venom Red Dot Sight",
      //imageRef:VortexOpticsVenomRedDotSightPic,
      id:"VortexOpticsVenomRedDotSight",
      itemLink:"../Optics/RedDotSights/Reviews/VortexOpticsVenomRedDotSight",
      itemBoldText:"Vortex Optics Venom Red Dot Sight",
      itemText:"The Vortex Venom Red Dot Sight is a highly regarded optic for its durability and versatility. It features a wide field of view and a crisp, clear dot that can be adjusted for brightness in various lighting conditions. This sight is perfect for both competitive shooting and tactical applications.",
      averageRating:4.7
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top Red Dot Sights for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={RedDotSightsPic} style={{height:'120px'}} alt="Red Dot Sights"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Red dot sights are excellent tools for enhancing shooting accuracy and speed, making them popular for hunting, tactical shooting, and recreational use. They provide a clear, illuminated point of aim, allowing users to quickly acquire targets without needing to perfectly align rear and front sights. Red dot sights are also great in low-light situations, as the illuminated reticle is easy to see even in dim conditions. Compact and lightweight, they’re easy to mount on various firearms, providing an intuitive aiming solution that enhances both performance and confidence.

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default RedDotSights;

