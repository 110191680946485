import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ZoleoSatelliteCommunicatorPic from '../images/ZoleoSatelliteCommunicator.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import SatelliteCommunicatorsPic from '../../../images/SatelliteCommunicators.jpg';


function ZoleoSatelliteCommunicator () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Zoleo Satellite Communicator</h1>
          Average Rating (4.6) <Rating name="read-only" value={4.6} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.zoleo.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ SatelliteCommunicatorsPic} style={{height:'120px'}} alt="SatelliteCommunicators"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Zoleo Satellite Communicator" height="130px" src={ZoleoSatelliteCommunicatorPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Many users love the Zoleo's user-friendly app and praise the ease of sending messages, which feels similar to traditional texting. They also highlight the dependable connection in remote areas, perfect for camping trips where cell signals fade. Some reviews, however, mention the occasional lag in message delivery, which can be concerning during urgent situations.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Two-way messaging</li>
<li className="ListItemStyle">User-friendly mobile app</li>
<li className="ListItemStyle">Affordable subscription options</li>
<li className="ListItemStyle">Real-time tracking</li>
<li className="ListItemStyle">Lightweight and compact</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited to messaging (no voice calls)</li>
<li className="ListItemStyle">Subscription fees can add up</li>
<li className="ListItemStyle">Lag in message delivery reported at times</li>
<li className="ListItemStyle">Battery life can drain with heavy use</li>
<li className="ListItemStyle">Requires GSM postpaid plan for SMS</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Two-way messaging</li>
<li className="ListItemStyle">User-friendly mobile app</li>
<li className="ListItemStyle">Affordable subscription options</li>
<li className="ListItemStyle">Real-time tracking</li>
<li className="ListItemStyle">Lightweight and compact</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited to messaging (no voice calls)</li>
<li className="ListItemStyle">Subscription fees can add up</li>
<li className="ListItemStyle">Lag in message delivery reported at times</li>
<li className="ListItemStyle">Battery life can drain with heavy use</li>
<li className="ListItemStyle">Requires GSM postpaid plan for SMS</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default ZoleoSatelliteCommunicator;

