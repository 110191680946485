import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import LowranceiFinderHuntCPic from '../images/LowranceiFinderHuntC.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import HandheldGPSPic from '../../../images/HandheldGPS.jpg';


function LowranceiFinderHuntC () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Lowrance iFinder Hunt C</h1>
          Average Rating (4.3) <Rating name="read-only" value={4.3} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.lowrance.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ HandheldGPSPic} style={{height:'120px'}} alt="HandheldGPS"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Lowrance iFinder Hunt C" height="130px" src={LowranceiFinderHuntCPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Hunting enthusiasts have found the iFinder Hunt C invaluable due to its specialized features tailored for tracking game. The detailed terrain information allows for better hunting strategies, leading to higher success rates. On the downside, users have reported that the user interface could be more intuitive and less cluttered.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Hunting-specific features</li>
<li className="ListItemStyle">Detailed terrain maps</li>
<li className="ListItemStyle">User-friendly for hunting applications</li>
<li className="ListItemStyle">Good battery performance</li>
<li className="ListItemStyle">Rugged and reliable build</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Cluttered user interface</li>
<li className="ListItemStyle">Slower than some competitors</li>
<li className="ListItemStyle">Limited additional features for general use</li>
<li className="ListItemStyle">Occasional GPS signal issues</li>
<li className="ListItemStyle">Bulky compared to other models</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Hunting-specific features</li>
<li className="ListItemStyle">Detailed terrain maps</li>
<li className="ListItemStyle">User-friendly for hunting applications</li>
<li className="ListItemStyle">Good battery performance</li>
<li className="ListItemStyle">Rugged and reliable build</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Cluttered user interface</li>
<li className="ListItemStyle">Slower than some competitors</li>
<li className="ListItemStyle">Limited additional features for general use</li>
<li className="ListItemStyle">Occasional GPS signal issues</li>
<li className="ListItemStyle">Bulky compared to other models</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default LowranceiFinderHuntC;

