import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ColemanClassicPropaneStovePic from '../images/ColemanClassicPropaneStove.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import CampStovesPic from '../../../images/CampStoves.jpg';


function ColemanClassicPropaneStove () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Coleman Classic Propane Stove</h1>
          Average Rating (4.5) <Rating name="read-only" value={4.5} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.coleman.com" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampStovesPic} style={{height:'120px'}} alt="CampStoves"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Coleman Classic Propane Stove" height="130px" src={ColemanClassicPropaneStovePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Customers appreciate the simplicity and effectiveness of the Coleman Classic Propane Stove, with many stating it lights up easily and burns evenly. Users also note its affordable price and lightweight nature, perfect for car camping. While some have raised concerns about wind exposure, they still find it dependable for most outdoor situations.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">User-friendly and easy to set up</li>
<li className="ListItemStyle">Lightweight and portable</li>
<li className="ListItemStyle">Affordable price point</li>
<li className="ListItemStyle">Two burners for simultaneous cooking</li>
<li className="ListItemStyle">Durable and weather-resistant design</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Sensitive to wind conditions</li>
<li className="ListItemStyle">Limited cooking space on burners</li>
<li className="ListItemStyle">Build quality may be lower than premium stoves</li>
<li className="ListItemStyle">Requires a propane tank (not included)</li>
<li className="ListItemStyle">Can be challenging to find replacement parts</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">User-friendly and easy to set up</li>
<li className="ListItemStyle">Lightweight and portable</li>
<li className="ListItemStyle">Affordable price point</li>
<li className="ListItemStyle">Two burners for simultaneous cooking</li>
<li className="ListItemStyle">Durable and weather-resistant design</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Sensitive to wind conditions</li>
<li className="ListItemStyle">Limited cooking space on burners</li>
<li className="ListItemStyle">Build quality may be lower than premium stoves</li>
<li className="ListItemStyle">Requires a propane tank (not included)</li>
<li className="ListItemStyle">Can be challenging to find replacement parts</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default ColemanClassicPropaneStove;

