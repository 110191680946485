import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import HandheldGPSPic from '../images/HandheldGPS.jpg';

//import GarmineTrex32xPic from './images/GarmineTrex32x.png';
//import GarminGPSMAP66iPic from './images/GarminGPSMAP66i.png';
//import GarminOregon700Pic from './images/GarminOregon700.png';
//import LowranceiFinderHuntCPic from './images/LowranceiFinderHuntC.png';
//import MagellaneXplorist310Pic from './images/MagellaneXplorist310.png';


function HandheldGPS () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.garmin.com",
      //imageAlt:"Garmin eTrex 32x",
      //imageRef:GarmineTrex32xPic,
      id:"GarmineTrex32x",
      itemLink:"../Electronics/HandheldGPS/Reviews/GarmineTrex32x",
      itemBoldText:"Garmin eTrex 32x",
      itemText:"The Garmin eTrex 32x is a compact and lightweight handheld GPS perfect for hiking, geocaching, and navigating tough terrains. It features preloaded topo maps and a high-sensitivity receiver that ensures accurate positioning, combined with a robust design for outdoor conditions.",
      averageRating:4.6
    },
	    {
      manuLink:"https://www.garmin.com/en-US/",
      //imageAlt:"Garmin GPSMAP 66i",
      //imageRef:GarminGPSMAP66iPic,
      id:"GarminGPSMAP66i",
      itemLink:"../Electronics/HandheldGPS/Reviews/GarminGPSMAP66i",
      itemBoldText:"Garmin GPSMAP 66i",
      itemText:"The Garmin GPSMAP 66i is a versatile handheld GPS that combines reliable mapping capabilities with satellite communication features. Ideal for outdoor enthusiasts, it provides topographic maps, weather data, and emergency SOS functions, making it suitable for hiking, camping, and other adventures.",
      averageRating:4.7
    },
	    {
      manuLink:"https://www.garmin.com",
      //imageAlt:"Garmin Oregon 700",
      //imageRef:GarminOregon700Pic,
      id:"GarminOregon700",
      itemLink:"../Electronics/HandheldGPS/Reviews/GarminOregon700",
      itemBoldText:"Garmin Oregon 700",
      itemText:"The Garmin Oregon 700 is an advanced handheld GPS equipped with a touchscreen display, wireless connectivity, and extensive mapping capabilities. Its lightweight design makes it perfect for hiking, while features like live tracking enhance the safety of outdoor activities.",
      averageRating:4.5
    },
	    {
      manuLink:"https://www.lowrance.com/",
      //imageAlt:"Lowrance iFinder Hunt C",
      //imageRef:LowranceiFinderHuntCPic,
      id:"LowranceiFinderHuntC",
      itemLink:"../Electronics/HandheldGPS/Reviews/LowranceiFinderHuntC",
      itemBoldText:"Lowrance iFinder Hunt C",
      itemText:"The Lowrance iFinder Hunt C is specifically tailored for hunting enthusiasts, providing seamless GPS features alongside map capabilities. Loaded with detailed terrain maps, it includes hunting-specific overlays to help users track their paths effortlessly in the field.",
      averageRating:4.3
    },
	    {
      manuLink:"https://www.magellangps.com/",
      //imageAlt:"Magellan eXplorist 310",
      //imageRef:MagellaneXplorist310Pic,
      id:"MagellaneXplorist310",
      itemLink:"../Electronics/HandheldGPS/Reviews/MagellaneXplorist310",
      itemBoldText:"Magellan eXplorist 310",
      itemText:"The Magellan eXplorist 310 is designed for outdoor navigation and geocaching enthusiasts. This rugged handheld GPS features a 3.0-inch color display, preloaded maps, and a comprehensive geocaching feature set, making it ideal for finding hidden treasures.",
      averageRating:4.4
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Top Handheld GPS for 2024</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={HandheldGPSPic} style={{height:'120px'}} alt="Handheld GPS"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Handheld GPS devices are a fun tool to have in the outdoors. They can help you navigate from point A to point B and mark items along the way. Do you need help getting back to your truck? A handheld GPS can help make that happen. Below are some great options for your next handheld device. <i>(Note: never rely solely on electronic devices when in the outdoors. Electronic devices can fail, so always have a backup.)</i>

<br/>

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <b style={{fontSize:'20px'}}>{element.itemBoldText}</b>
                      <div style={{float:'right'}}>
                        Average Rating ({element.averageRating}) <Rating name="read-only" value={element.averageRating} precision={0.5} readOnly />
                      </div>
                      
                      <br/>
                      <br/>
                      {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button style={{marginTop:'10px'}} key={`${element.id}-b1`} variant="contained">Product Review</Button>
                      </Link>
                      <Link href={element.manuLink} target="_blank">
                        <Button style={{marginLeft:'20px', marginTop:'10px'}} variant="contained">Manufacturer's Website</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default HandheldGPS;

