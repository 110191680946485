import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ALPSMountaineeringKingKongChairPic from '../images/ALPSMountaineeringKingKongChair.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import CampChairsPic from '../../../images/CampChairs.jpg';


function ALPSMountaineeringKingKongChair () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for ALPS Mountaineering King Kong Chair</h1>
          Average Rating (4.7) <Rating name="read-only" value={4.7} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.alpsbrands.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampChairsPic} style={{height:'120px'}} alt="CampChairs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="ALPS Mountaineering King Kong Chair" height="130px" src={ALPSMountaineeringKingKongChairPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Reviewers appreciate the spacious design of the King Kong Chair, noting it provides ample room and comfort. Many also commend its durability and multiple storage options, which are great for keeping essentials nearby. Some users point out that its bulkiness can make it less portable for hiking trips.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Extra-wide seat for comfort</li>
<li className="ListItemStyle">Durable and sturdy construction</li>
<li className="ListItemStyle">Multiple storage pockets</li>
<li className="ListItemStyle">Supports heavier weights</li>
<li className="ListItemStyle">Easy to set up and fold</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Bulkier compared to other camp chairs</li>
<li className="ListItemStyle">Heavier for carrying</li>
<li className="ListItemStyle">Fabric can fade with prolonged sun exposure</li>
<li className="ListItemStyle">Not suitable for backpacking</li>
<li className="ListItemStyle">Pricey compared to basic models</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Extra-wide seat for comfort</li>
<li className="ListItemStyle">Durable and sturdy construction</li>
<li className="ListItemStyle">Multiple storage pockets</li>
<li className="ListItemStyle">Supports heavier weights</li>
<li className="ListItemStyle">Easy to set up and fold</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Bulkier compared to other camp chairs</li>
<li className="ListItemStyle">Heavier for carrying</li>
<li className="ListItemStyle">Fabric can fade with prolonged sun exposure</li>
<li className="ListItemStyle">Not suitable for backpacking</li>
<li className="ListItemStyle">Pricey compared to basic models</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default ALPSMountaineeringKingKongChair;

