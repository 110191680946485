import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BushnellTrophyCamHDPic from '../images/BushnellTrophyCamHD.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import TrailCamsPic from '../../../images/TrailCams.jpg';


function BushnellTrophyCamHD () {

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (


    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Review for Bushnell Trophy Cam HD</h1>
          Average Rating (4.6) <Rating name="read-only" value={4.6} precision={0.5} readOnly />
          <div style={{float:'right', marginRight:"20px"}}>
            <Link href="https://www.bushnell.com/" target="_blank">
              <Button variant="contained">Manufacturer's Website</Button>
            </Link>
          </div>
        </td>
        <td>
          <img className="RoundedCorners" src={ TrailCamsPic} style={{height:'120px'}} alt="TrailCams"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Bushnell Trophy Cam HD" height="130px" src={BushnellTrophyCamHDPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        Many users rave about the construction quality and durability of the Trophy Cam, often mentioning how it withstands harsh weather conditions. The quality of both photos and videos has been received warmly, especially for capturing details in low light. A few users did mention some difficulty with the interface, but most agree that the image quality far outweighs any quirks.
        <br/>
        <br/>

    </Typography>

     {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
    <Box sx={{ flexGrow: 1, }}>
      <table style={{width:"100%", tableLayout:'fixed'}}>
        <tbody>
          <tr>
        <td style={{paddingRight:"10px"}}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Exceptional image quality</li>
<li className="ListItemStyle">Rugged and durable</li>
<li className="ListItemStyle">Fast trigger speed</li>
<li className="ListItemStyle">User-friendly setup</li>
<li className="ListItemStyle">Great battery life</li>

              </ul>

        </Paper>
        </td>
        <td>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Interface can be tricky</li>
<li className="ListItemStyle">Higher price point</li>
<li className="ListItemStyle">Occasional issues with motion detection</li>
<li className="ListItemStyle">Limited storage capacity</li>
<li className="ListItemStyle">Could benefit from more advanced features</li>
			  
              </ul>

        </Paper>
      </td>
      </tr>
      </tbody>
      </table>
    </Box>
    </div>
     }
    {isSmallScreen === true &&
      <div style={{marginLeft: isSmallScreen ? '20px' : '10px', marginTop: '10px', marginRight:'20px'}}>
 <Box sx={{ flexGrow: 1, }}>

        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Exceptional image quality</li>
<li className="ListItemStyle">Rugged and durable</li>
<li className="ListItemStyle">Fast trigger speed</li>
<li className="ListItemStyle">User-friendly setup</li>
<li className="ListItemStyle">Great battery life</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Interface can be tricky</li>
<li className="ListItemStyle">Higher price point</li>
<li className="ListItemStyle">Occasional issues with motion detection</li>
<li className="ListItemStyle">Limited storage capacity</li>
<li className="ListItemStyle">Could benefit from more advanced features</li>
			  
              </ul>

        </Paper>
    </Box>
    </div>
    }
    <br/>
    </div>

  );
}

export default BushnellTrophyCamHD;

